import { Link } from "react-router-dom";
import { Chevron } from "../assets/index.js";
import { useMediaQuery } from "react-responsive";
import { twMerge } from "tw-merge";

const Cta = ({ text, arrowOrder, textOrder, onClickFunction }) => {
  const isSmall = useMediaQuery({ maxWidth: "762px" });
  return (
    <button
      onClick={onClickFunction}
      className={`${
        isSmall ? "text-base-medium" : "text-lg-medium"
      }  text-brand-500 flex items-center gap-2 group `}
    >
      {text}
      <Chevron
        classname={`fill-brand-500 -translate-x-1 group-hover:translate-x-0 transition-all duration-200 ${arrowOrder}`}
      />
    </button>
  );
};

export default Cta;
