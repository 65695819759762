const Chevron = ({ classname }) => {
  return (
    <svg
      className={`${classname}`}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.2741 12.4243C6.03978 12.1899 6.03978 11.8101 6.2741 11.5758L9.84981 8.00002L6.2741 4.42429C6.03978 4.18997 6.03978 3.81007 6.2741 3.57576C6.50842 3.34145 6.88826 3.34145 7.12258 3.57576L11.1226 7.57576C11.3569 7.81007 11.3569 8.18994 11.1226 8.42426L7.12258 12.4243C6.88826 12.6586 6.50842 12.6586 6.2741 12.4243Z"
      />
    </svg>
  );
};

export default Chevron;
