import { useMediaQuery } from "react-responsive";
import { Navbar, FooterSection, Cta, BtnPrimary } from "../components/index.js";
import { school, verified, bapliSchool } from "../assets/index.js";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link as Scrolling } from "react-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const KategoriRole = () => {
  const isSmall = useMediaQuery({ maxWidth: "680px" });
  const xSmall = useMediaQuery({ minWidth: "400px" });

  const [dataRaw, setDataRaw] = useState([]);
  const fetchData = async () => {
    try {
      const result = await axios.get("http://localhost:3001/api/roles");
      setDataRaw(result.data);
      console.log(
        result.data.map(
          (ele, index) => `http://localhost:3001/api/getImage/${ele.image_role}`
        )
      );
    } catch (error) {}
  };

  const onClickFunction = (event) => {
    event.preventDefault();
    window.history.back();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Card = ({ title, desc, id, imageRole }) => (
    <Link className="w-full max-w-[300px]" to={`/academy/role/${id}/bagian`}>
      <div className="pl-0 pt-0 lg:pl-9 lg:pt-12 hover:translate-y-3 transition-all duration-200 w-full">
        <div className="card-course px-[10px] py-3 rounded-lg border border-gray-200 shadow-lg shadow-gray-200 w-auto flex flex-col gap-4">
          <img
            src={imageRole}
            className="w-full h-[180px] rounded
          "
            alt=""
            srcset=""
          />
          <div className="content-desc flex flex-col gap-8">
            <div className="text-title flex flex-col gap-1">
              <h1 className="text-lg-bold text-gray-950 ">{title}</h1>
              <h1 className="text-base-medium text-gray-700">
                {desc} Pembelajaran
              </h1>
            </div>
            <div className="watermark flex items-center gap-2 px-[10px] py-1 rounded-lg border border-gray-300">
              <div className="logo flex items-center gap-1">
                <img src={bapliSchool} alt="" srcset="" />
                <span className="text-xs-medium text-gray-900">
                  Oleh Instruktur Bapli School
                </span>
              </div>
              <img src={verified} alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );

  return (
    <>
      <div className="fixed z-50 w-full transition-all duration-300">
        <Navbar classname="top-14" />
      </div>
      {/* hero container */}
      <div className="hero-section px-5 md:px-[80px] flex flex-col gap-6 pt-28 md:pt-32">
        <Cta
          onClickFunction={onClickFunction}
          text="Pelajari Aplikasi Lain"
          arrowOrder="order-first rotate-180"
        />
        <div
          style={{ backgroundImage: `url(${school})` }}
          className="main-content bg-gray-900 bg-cover bg-center  px-8 py-[42px] h-fit md:h-[400px] flex items-end justify-start text-white rounded-3xl relative overflow-hidden"
        >
          <div className="absolute  left-0 top-[0.5px] w-full -z-0 h-full overflow-hidden">
            <div className="w-full h-full absolute  bg-gradient-to-r from-neutral-950"></div>
          </div>

          <div className="content-contain flex flex-col gap-14 w-[600px] z-30 ">
            <div className="header-contain flex flex-col gap-4">
              <h1 className={`${isSmall ? "text-3xl-bold" : "text-4xl-bold"}`}>
                Sekarang, mulai pelajari aplikasi Edutren!
              </h1>
              <p
                className={`${
                  isSmall ? "text-base-medium" : "text-lg-medium"
                } text-gray-400`}
              >
                Mulai pelajari aplikasi Edutren. Ada berbagai pilihan
                pembelajaran role, dari guru, hingga orang tua murid!
              </p>
            </div>

            <Scrolling
              activeClass="active"
              to="kategoriRole"
              spy={true}
              smooth={true}
              offset={100}
              duration={500}
            >
              <BtnPrimary
                icon="hidden"
                classname="w-fit"
                text="Pilih  Pembelajaran"
              />
            </Scrolling>
          </div>
        </div>
      </div>
      {/* hero container */}
      {/* content container */}
      <div
        id="kategoriRole"
        className="content-section px-5 md:px-10 pt-24 flex flex-col gap-2 w-full mb-36 "
      >
        <div className="max-w-[1500px]">
          <div className="header flex flex-col gap-2  px-5  mb-8 md:mb-0">
            <h1
              className={`${
                isSmall ? "text-2xl-bold" : "text-3xl-bold"
              } text-gray-900`}
            >
              Pilih Kategori
            </h1>
            <p
              className={`${
                isSmall ? "text-base-medium" : "text-xl-medium"
              } text-gray-600`}
            >
              Pelajari kategori pembelajaran, dari role murid, hingga guru,
              semuanya tersedia.{" "}
            </p>
          </div>
          {isSmall ? (
            <div className="px-5 md:px-[140px] lg:px-[180px]">
              <Swiper
                slidesPerView={xSmall ? 2 : 1}
                spaceBetween={16}
                pagination={{
                  dynamicMainBullets: 1,
                  dynamicBullets: true,
                  paginationClass: "custom-pagination",
                }}
                modules={[Pagination]}
                className="mySwiper w-full"
              >
                {dataRaw.map((ele, index) => (
                  <SwiperSlide className="pb-10">
                    <Card
                      title={ele.judul_role}
                      id={ele._id}
                      desc={ele.data_bagian.length}
                      imageRole={`http://localhost:3001/api/getImage/${ele.image_role}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <div className="card-container gap-5 md:gap-4 lg:gap-0 flex   flex-wrap w-full">
              {dataRaw.map((ele, index) => (
                <Card
                  title={ele.judul_role}
                  id={ele._id}
                  desc={ele.data_bagian.length}
                  imageRole={`http://localhost:3001/api/getImage/${ele.image_role}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {/* content container */}
      <FooterSection />
    </>
  );
};

export default KategoriRole;
