import { useMediaQuery } from "react-responsive";
import { ArrowDown } from "../assets/index.js";
import { faqList } from "../constant/index.js";
import { Disclosure } from "@headlessui/react";

const DisclosureItem = ({ desc, title }) => {
  const isSmall = useMediaQuery({ maxWidth: "768px" });

  return (
    <Disclosure>
      {({ open }) => (
        <div
          className={`${
            open ? "shadow-xl" : "shadow-sm"
          }  px-8 py-6 rounded-lg border border-[#EAEAEA] bg-white`}
        >
          <Disclosure.Button className="flex items-start w-full ">
            <span
              className={` ${open ? "mb-3" : "mb-0"}  ${
                isSmall ? "text-lg-bold" : "text-xl-bold"
              } inline-block  font-bold text-xl tracking-[-0.54]  text-left w-full`}
            >
              {title}
            </span>
            <img
              className={`${
                open ? "rotate-180" : "rotate-0"
              } transition-all duration-300`}
              src={ArrowDown}
              alt=""
              srcset=""
            />
          </Disclosure.Button>
          <Disclosure.Panel className={`text-base-medium text-gray-700`}>
            {desc}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

const FaqSection = () => {
  const isSmall = useMediaQuery({ maxWidth: "768px" });

  return (
    <section className="faqSection bg-[#FAFAFA] py-16 px-6 md:px-40" id="faq">
      <div className="textContainer mb-12 flex flex-col gap-8 max-w-[900px] mx-auto ">
        <div>
          <div className="w-full flex justify-center"></div>

          <h1
            className={`${
              isSmall ? "text-2xl-bold" : "text-3xl-bold"
            } text-center`}
          >
            Pertanyaan yang sering ditanyakan
          </h1>
        </div>
      </div>
      <div className="max-w-[950px] space-y-8 mx-auto">
        {faqList.map((ele, index) => (
          <DisclosureItem desc={ele.desc} title={ele.title} />
        ))}
      </div>
    </section>
  );
};

export default FaqSection;
