import { useMediaQuery } from "react-responsive";
import { imageSmile, Chevron } from "../assets/index.js";
import { Navbar, BtnPrimary, FooterSection } from "../components/index.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { dataAplikasi } from "../constant/index.js";
import { Badge } from "flowbite-react";
import {
  Link as Scrolling,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

const KategoriAplikasi = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      y: "30vh",
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: "-30vh",
    },
  };
  const isSmall = useMediaQuery({ maxWidth: "768px" });

  const Cta = ({ text, classname, path }) => (
    <Link
      to={path}
      className={`${
        isSmall ? "text-lg-medium" : "text-xl-medium"
      } text-brand-500 flex items-center gap-2 group ${text}`}
    >
      Pelajari Aplikasi
      <Chevron
        classname={`fill-brand-500 -translate-x-1 group-hover:translate-x-0 transition-all duration-200 ${classname}`}
      />
    </Link>
  );

  const Card = ({ background, title, deskripsi, badge }) => (
    <>
      <Link to="/academy/role">
        <div
          className={`card classname flex flex-col md:flex-row gap-8 md:gap-14 md:px-8 md:py-6 px-5 py-8 rounded-3xl border border-gray-300 shadow-2xl shadow-gray-200 items-center`}
        >
          <div
            style={{ backgroundImage: `url(${background})` }}
            className="w-full min-w-0 md:min-w-[420px] h-[200px] max-w-[420px] md:h-[300px] rounded-2xl bg-cover"
          ></div>
          <div className={`text-contain flex flex-col gap-11`}>
            <div className="header flex-col flex gap-6">
              <div className="badge flex items-center gap-2">
                <img className="max-w-28" src={badge} alt="" srcset="" />
              </div>
              <div className={`text-header flex flex-col gap-3 `}>
                <h1
                  className={`${
                    isSmall ? "text-2xl-bold" : "text-3xl-bold"
                  } text-gray-900`}
                >
                  {title}
                </h1>
                <p
                  className={`${
                    isSmall ? "text-lg-medium" : "text-xl-medium"
                  } text-gray-600`}
                >
                  {deskripsi}
                </p>
              </div>
            </div>
            <Cta path="/academy/role" />
          </div>
        </div>
      </Link>
    </>
  );

  const CardDisable = ({ background, title, deskripsi, badge }) => (
    <>
      <div
        className={`card classname flex flex-col md:flex-row gap-8 md:gap-14 md:px-8 md:py-6 px-5 py-8 rounded-3xl border border-gray-300 shadow-2xl shadow-gray-200 items-center`}
      >
        <div
          style={{ backgroundImage: `url(${background})` }}
          className="w-full bg-contain bg-center min-w-0 md:min-w-[420px] h-[200px] max-w-[420px] md:h-[300px] rounded-2xl"
        ></div>
        <div className={`text-contain flex flex-col gap-11`}>
          <div className="header flex-col flex gap-6">
            <div className="badge flex items-center gap-2">
              <img className="max-w-28" src={badge} alt="" srcset="" />
              <Badge color="info">Segera Tersedia</Badge>
            </div>
            <div className={`text-header flex flex-col gap-3 `}>
              <h1
                className={`${
                  isSmall ? "text-2xl-bold" : "text-3xl-bold"
                } text-gray-900`}
              >
                {title}
              </h1>
              <p
                className={`${
                  isSmall ? "text-lg-medium" : "text-xl-medium"
                } text-gray-600`}
              >
                {deskripsi}
              </p>
            </div>
          </div>
          <Cta classname="fill-gray-500" text="text-gray-700" />
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="fixed z-50 w-full transition-all duration-300">
        <Navbar classname="top-14" />
      </div>
      <motion.div
        key="/"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={{ type: "tween", ease: "easeOut", duration: 0.6 }}
      >
        <div className="hero-container  flex flex-col md:grid md:grid-cols-12 px-6 md:px-[140px] xl:px-[200px] items-center py-9 md:pt-28 bg-gray-100">
          <div className="text-header pr-0 md:pr-[110px] col-span-7 flex flex-col gap-8">
            <div className="text-contain flex flex-col gap-4">
              <h1
                className={`${
                  isSmall ? "text-3xl-bold" : "text-4xl-bold"
                } text-gray-900`}
              >
                Mulai Pelajari aplikasi aplikasi Bapli school secara Gratis!
              </h1>
              <p
                className={`${
                  isSmall ? "text-base-medium" : "text-lg-medium"
                } text-gray-600`}
              >
                Bapli School kini memiliki pembelajaran 101 supaya pengguna
                setia dapat dengan mudah menegerti tentang aplikasi-aplikasi
                dari Baplischool
              </p>
            </div>
            <Scrolling
              activeClass="active"
              to="aboutSection"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <BtnPrimary
                icon="hidden"
                classname="w-fit"
                text="Pilih Pembelajaran Aplikasi"
              />
            </Scrolling>
          </div>
          <div className="img-smile min-w-[430px] col-span-5 w-full hidden md:block">
            <img src={imageSmile} className="w-full" alt="" srcset="" />
          </div>
        </div>
      </motion.div>
      <div className="card-container px-5 md:px-[140px] xl:px-[200px] flex flex-col gap-10 md:gap-24 pt-20 md:pt-36 mb-24">
        <div className="header text-center flex flex-col gap-5">
          <h1
            className={`${
              isSmall ? "text-3xll-bold" : "text-4xl-bold"
            } text-gray-900`}
          >
            Pilih aplikasi yang mau kamu pelajari!
          </h1>
          <p
            className={`${
              isSmall ? "text-base-medium" : "text-xl-medium"
            } text-gray-600`}
          >
            Mulai dari aplikasi Edutren, Simakdis, sampai mobile Digitren!
          </p>
        </div>
        <div
          id="kategoriAplikasi"
          className="card-content flex flex-col gap-11 md:gap-16"
        >
          {dataAplikasi.map((ele, index) => (
            <>
              {ele.id < 2 ? (
                <Card
                  background={ele.imgCover}
                  title={ele.title}
                  deskripsi={ele.deskripsi}
                  badge={ele.badge}
                />
              ) : (
                <CardDisable
                  background={ele.imgCover}
                  title={ele.title}
                  deskripsi={ele.deskripsi}
                  badge={ele.badge}
                />
              )}
            </>
          ))}
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default KategoriAplikasi;
