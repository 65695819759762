import React from "react";
import { Logo, logoBapli } from "../../assets/index.js";
import { footerItems } from "../../constant";

const FooterSection = () => {
  return (
    <footer className="py-[42px]  px-5 md:px-[90px] flex flex-col gap-[46px] ">
      <div className="main-content">
        <div className="menu-container  flex justify-between flex-col md:flex-row gap-10 md:gap-8">
          <div className="">
            <img src={logoBapli} alt="" className="min-w-[300px]" />
          </div>
          <div className="items flex flex-col md:flex-row justify-between gap-10 md:gap-[54px]">
            {footerItems.map((element, index) => (
              <div className="column">
                <div className="item flex flex-col gap-5">
                  <header className="text-xl-semibold text-gray-900">
                    {element.title}
                  </header>
                  <div className="item flex flex-col gap-3">
                    {element.menu.map((ele, index) => (
                      <a href={ele.link}>
                        <div className="flex flex-row gap-2">
                          <img
                            src={ele.icon}
                            alt=""
                            srcset=""
                            className={`${element.id > 2 ? "flex" : "hidden"}`}
                          />

                          <span className="text-base-medium text-gray-700">
                            {ele.list}
                          </span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="divider w-full h-[1px] border-b-2 border-gray-400 border-dashed"></div>
      <span className="text-center inline-block w-full text-lg-medium text-gray-950">
        © Copyright 2023 PT Bengkel Aplikasi Nusantara
      </span>
    </footer>
  );
};

export default FooterSection;
