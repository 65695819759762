import React from "react";

const Whatsapp = ({ classname }) => {
  return (
    <svg
      className={classname}
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.2876 3.68254C13.6 2.98799 12.781 2.4373 11.8783 2.06258C10.9757 1.68787 10.0074 1.49664 9.0301 1.50004C4.9351 1.50004 1.5976 4.83754 1.5976 8.93254C1.5976 10.245 1.9426 11.52 2.5876 12.645L1.5376 16.5L5.4751 15.465C6.5626 16.0575 7.7851 16.3725 9.0301 16.3725C13.1251 16.3725 16.4626 13.035 16.4626 8.94004C16.4626 6.95254 15.6901 5.08504 14.2876 3.68254ZM9.0301 15.1125C7.9201 15.1125 6.8326 14.8125 5.8801 14.25L5.6551 14.115L3.3151 14.73L3.9376 12.45L3.7876 12.2175C3.17091 11.2328 2.84345 10.0945 2.8426 8.93254C2.8426 5.52754 5.6176 2.75254 9.0226 2.75254C10.6726 2.75254 12.2251 3.39754 13.3876 4.56754C13.9632 5.14052 14.4194 5.82204 14.7296 6.57261C15.0399 7.32319 15.1981 8.12788 15.1951 8.94004C15.2101 12.345 12.4351 15.1125 9.0301 15.1125ZM12.4201 10.4925C12.2326 10.4025 11.3176 9.95254 11.1526 9.88504C10.9801 9.82504 10.8601 9.79504 10.7326 9.97504C10.6051 10.1625 10.2526 10.5825 10.1476 10.7025C10.0426 10.83 9.9301 10.845 9.7426 10.7475C9.5551 10.6575 8.9551 10.455 8.2501 9.82504C7.6951 9.33004 7.3276 8.72254 7.2151 8.53504C7.1101 8.34754 7.2001 8.25004 7.2976 8.15254C7.3801 8.07004 7.4851 7.93504 7.5751 7.83004C7.6651 7.72504 7.7026 7.64254 7.7626 7.52254C7.8226 7.39504 7.7926 7.29004 7.7476 7.20005C7.7026 7.11004 7.3276 6.19504 7.1776 5.82004C7.0276 5.46004 6.8701 5.50504 6.7576 5.49754H6.3976C6.2701 5.49754 6.0751 5.54254 5.9026 5.73004C5.7376 5.91754 5.2576 6.36754 5.2576 7.28254C5.2576 8.19754 5.9251 9.08254 6.0151 9.20254C6.1051 9.33004 7.3276 11.205 9.1876 12.0075C9.6301 12.2025 9.9751 12.315 10.2451 12.3975C10.6876 12.54 11.0926 12.5175 11.4151 12.4725C11.7751 12.42 12.5176 12.0225 12.6676 11.5875C12.8251 11.1525 12.8251 10.785 12.7726 10.7025C12.7201 10.62 12.6076 10.5825 12.4201 10.4925Z" />
    </svg>
  );
};

export default Whatsapp;
