import React, { useEffect, useState } from "react";
import { Header } from "../index.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

const BlogSection = () => {
  const [slide, setSlide] = useState(1);

  const isMedium = useMediaQuery({ minWidth: "600px" });
  const isLarge = useMediaQuery({ minWidth: "1200px" });
  const isExtraLarge = useMediaQuery({ minWidth: "1700px" });
  const isSmall = useMediaQuery({ maxWidth: "768px" });
  const [dataraw, setDataraw] = useState([]);
  const [rubrik, setRubrik] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://cms.mediana.id/sb_beritaservice/v2/berita?rubrik=4&urutkan=tgl_dikurasi&kolom=id%2Cheadline%2Ctgl_dikurasi%2Crubrik_id%2Cgambar_link%2Cyoutube_link%2Ctipe%2Ccontent%2Ctipe&contentTrim=200&paginasi=1&perHalaman=8&page=20"
        );
        setDataraw(response.data.data);
        dataraw.map((ele, index) => setRubrik(ele.rubrik));
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (isExtraLarge) {
      setSlide(4);
    } else if (isLarge) {
      setSlide(3);
    } else if (isMedium) {
      setSlide(2);
    } else {
      setSlide(1);
    }
  }, [isLarge, isMedium]);

  const BlogCard = ({ title, desc, img }) => (
    <div className="flex flex-col gap-4  max-w-[550px]">
      <div
        style={{ backgroundImage: `url(${img})` }}
        className="img-background bg-no-repeat bg-cover w-full h-[250px] md:h-[300px] min-w-[250px] rounded-xl text-lg"
      ></div>
      <div className="text-container flex flex-col gap-2">
        <h1
          className={`${
            isExtraLarge ? "text-2xl-bold" : "text-2xl-bold"
          }  text-gray-950 line-clamp-2`}
        >
          {title}
        </h1>
        <p className="text-lg-medium text-gray-800 line-clamp-2">{desc}</p>
      </div>
    </div>
  );
  return (
    <section className="blog-section px-5 md:px-[140px] py-20 flex flex-col gap-[32px]">
      <Header
        title="Baca dan temukan artikel tentang Baplischool"
        subtitle="Jauh lebih mengenal tentang kami"
        button="hidden"
        center=""
      />
      {/* <div className="blogContainer grid grid-cols-3 gap-10"> */}
      <Swiper
        slidesPerView={slide}
        spaceBetween={32}
        pagination={{
          dynamicMainBullets: 1,
          dynamicBullets: true,
          paginationClass: "custom-pagination",
        }}
        modules={[Pagination]}
        className="mySwiper w-full"
      >
        {dataraw.map((ele, index) => (
          <SwiperSlide className="pb-10">
            <a
              target="blank"
              href={`https://mediana.id/${rubrik.nama}/${ele.id}/${ele.headline}`}
            >
              <BlogCard
                title={ele.headline}
                desc={ele.content}
                img={ele.gambar_thumbnail}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* </div> */}
    </section>
  );
};

export default BlogSection;
