import React from "react";
import { navbarItems, solusiItems, informasiItems } from "../../constant";
import { Logo, Chevron, menuIcon } from "../../assets/index.js";
import { useMediaQuery } from "react-responsive";
import "../../App.css";
import { Link } from "react-router-dom";

const NavLinks = ({ classname }) => {
  const isLarge = useMediaQuery({ minWidth: "1700px" });

  const SolusiItem = ({ title, img, desc }) => (
    <>
      <div className="main-contain p-3 hover:bg-brand-50 flex items-center gap-2 rounded-lg cursor-pointer">
        <img className="w-7" src={img} alt="" srcset="" />
        <div className="text flex flex-col gap-[-4px]">
          <header className={`text-base-extrabold text-gray-950 `}>
            {title}
          </header>
          <p className={`text-gray-700 text-small-semibold`}>{desc}</p>
        </div>
      </div>
    </>
  );
  const ListItems = ({ title, image, desc, link }) => (
    <a target="blank" href={link} className={`group/produk w-full`}>
      <div className="list flex flex-row gap-3 bg-white group-hover/produk:bg-gray-200 p-2 rounded-lg items-center">
        <div className="main-container flex items-center gap-2">
          <div className="img-container rounded-lg">
            <img src={image} alt="" className="w-[46px]" />
          </div>
          <div className="text-container">
            <header className="text-lg-bold text-gray-900">{title}</header>
            <p className="text-base-medium text-gray-700">{desc}</p>
          </div>
        </div>

        <Chevron classname="fill-gray-800 group-hover/produk:translate-x-0 -translate-x-1 transition-all duration-200" />
      </div>
    </a>
  );
  return (
    <>
      <ul className="flex gap-5">
        {navbarItems.map((ele, index) => (
          <li
            className={` ${
              isLarge ? "text-lg-semibold" : "text-base-medium"
            } text-gray-800 group flex gap-1 items-center  py-5`}
            key={index}
          >
            {/* item */}
            <Link
              to={`${ele.id === 5 ? "/price" : ele.id === 6 ? "/about" : ""}`}
              className="group-hover:text-brand-500 transition-all duration-150 flex items-center gap-2"
            >
              {ele.item}
              <Chevron
                classname={`rotate-90 fill-gray-700 group-hover:fill-brand-500 group-hover:rotate-[270deg] transition-all duration-200 ${
                  ele.id < 4 ? "flex" : "hidden"
                }`}
              />
            </Link>
            {/* item */}

            {ele.status && (
              <div
                className={`absolute ${classname}  z-index: 9999 -translate-y-2 ease-in-out invisible group-hover:translate-y-0 group-hover:visible transition-all duration-300`}
              >
                {/* mega menu 1 */}
                <div
                  className={`main-contain  min-w-full ${
                    ele.id === 1 ? "block" : "hidden"
                  }`}
                >
                  <div className="absolute-contain rounded-lg">
                    <div className="padding py-3"></div>
                    <div className="mega-menu items-container p-3 bg-white ">
                      {ele.subitems.map((element, index) => (
                        <ListItems
                          key={index}
                          link={element.link}
                          image={element.img}
                          title={element.title}
                          desc={element.desc}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {/* mega menu 1 */}

                {/* mega menu 2 */}
                <div
                  className={`main-contain  min-w-full ${
                    ele.id === 2 ? "block" : "hidden"
                  }`}
                >
                  <div className="absolute-contain rounded-lg">
                    <div className="padding py-3"></div>
                    <div className={`mega-menu hidden  group-hover:block p-3`}>
                      {solusiItems.map((ele, index) => (
                        <SolusiItem
                          key={index}
                          title={ele.title}
                          img={ele.img}
                          desc={ele.desc}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {/* mega menu 2 */}

                {/* mega menu 3 */}
                <div
                  className={`main-contain  min-w-full ${
                    ele.id === 3 ? "block" : "hidden"
                  }`}
                >
                  <div className="absolute-contain rounded-lg">
                    <div className="padding py-3"></div>
                    <div className={`mega-menu hidden  group-hover:block p-3`}>
                      {informasiItems.map((ele, index) => (
                        <a target="blank" href={ele.link}>
                          <SolusiItem
                            key={index}
                            title={ele.title}
                            img={ele.img}
                            desc={ele.desc}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {/* mega menu 3 */}
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default NavLinks;
