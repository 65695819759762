import React from "react";
import { Arrow } from "../../assets/index.js";
import { twMerge } from "tw-merge";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Information = () => {
  const isMedium = useMediaQuery({ minWidth: "960px" });
  const Chip = () => (
    <a className="text-small-semibold py-[6px] px-[12px] rounded-full bg-brand-800 flex items-center hover:bg-brand-900 cursor-pointer gap-1 min-w-fit">
      <span className=" hidden md:flex text-small-semibold">
        Mulai Pelajari
      </span>
      <Arrow />
    </a>
  );
  return (
    <Link to="/academy">
      <div className=" bg-brand-500 w-full py-2 text-white flex gap-3 items-center justify-center px-5 relative">
        <h1
          className={`${
            isMedium ? "text-base-semibold" : "text-small-semibold"
          }`}
        >
          Mulai belajar tentang aplikasi Baplischool
        </h1>
        <Chip />
      </div>
    </Link>
  );
};

export default Information;
