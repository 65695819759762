import React from "react";
import {
  Navbar,
  Information,
  FooterSection,
  BtnPrimary,
  BlogSection,
} from "../components/index.js";
import { useMediaQuery } from "react-responsive";
import {
  mouseIcon,
  imageSatu,
  imageDua,
  imageTiga,
  imageEmpat,
} from "../assets/index.js";

const AboutPage = () => {
  const IsSmall = useMediaQuery({ maxWidth: "768px" });
  const Header = () => (
    <>
      <div className="mb-3 md:mb-32">
        <span className="text-lg-bold uppercase text-brand-500 block  mt-[62px] mb-5">
          Tentang Bapli School
        </span>
        <div className="mb-9">
          <h1
            className={`${
              IsSmall ? "text-4xl-medium" : "text-7xl-medium"
            } text-gray-900 mb-8`}
          >
            Raih efisiensi pengalaman pembelajaran all produk digital
            <span className="text-brand-500"> Bapli School </span>
            dalam satu platform
          </h1>
        </div>
        <div className="mouse-icon flex gap-3">
          <img src={mouseIcon} alt="" srcset="" />
          <span
            className={`${
              IsSmall ? "text-base-medium" : "text-2xl-medium"
            } text-gray-600`}
          >
            Scroll ke bawah untuk baca selengkapnya
          </span>
        </div>
      </div>
    </>
  );

  const ItemsList = ({ title, desc }) => (
    <div className="flex flex-col gap-2">
      <span className="pl-2 border-l-2 border-brand-500 text-xl-bold text-gray-900">
        {title}
      </span>
      <p className="text-base text-base-medium text-gray-600">{desc}</p>
    </div>
  );
  return (
    <>
      <div className="main-content">
        <nav>
          <Information />
          <Navbar classname="top-24" />
        </nav>

        <div className="flex flex-col gap-4 md:gap-[120px]">
          <div className="text-contain md:px-[90px]  xl:px-[140px] px-5 ">
            <Header />
            <div className="about-container flex-col flex md:grid grid-cols-12 py-20 gap-12 xl:gap-36">
              <div className="about-text col-span-6 flex flex-col gap-14 md:gap-20">
                <div className="flex flex-col gap-11 md:gap-11">
                  <div className="text-header flex flex-col gap-5">
                    <header
                      className={`${
                        IsSmall ? "text-3xl-bold" : "text-4xl-bold"
                      } text-gray-900`}
                    >
                      Tentang Kami, Bapli School
                    </header>
                    <p
                      className={`${
                        IsSmall ? "text-base-medium" : "text-xl-medium"
                      } text-gray-600`}
                    >
                      Bapli School hadir sebagai platform dan ekosistem untuk
                      para pengusaha, pemimpin, dan individu yang bersemangat
                      dalam mengarungi gelombang transformasi digital.
                    </p>
                  </div>
                  <div className="list-container flex-col flex md:flex-row gap-5">
                    <ItemsList
                      title="100 + Mitra"
                      desc="Mitra Bapli School berupa sekolah umum dan pesantren"
                    />
                    <ItemsList
                      title="5000+ Pengguna"
                      desc="Pengguna Bapli School merupakan kalangan Kepala Sekolah, Murid, Santri, Tenaga Akuntan hingga Guru"
                    />
                  </div>
                </div>
                <BtnPrimary
                  classname="w-fit"
                  icon="hidden"
                  text="Lihat semua aplikasi"
                />
              </div>
              <div className="image order-first md:order-last col-span-6 w-full min-h-[400px] h-full grid grid-rows-7 grid-cols-2 gap-5">
                <div
                  style={{ backgroundImage: `url(${imageSatu})` }}
                  className="w-full row-span-3 h-full  rounded-lg bg-cover"
                ></div>
                <div
                  style={{ backgroundImage: `url(${imageDua})` }}
                  className="w-full row-span-4 h-full  rounded-lg  bg-cover"
                ></div>
                <div
                  style={{ backgroundImage: `url(${imageTiga})` }}
                  className="w-full  row-span-4 h-full  rounded-lg  bg- bg-no-repeat"
                ></div>
                <div
                  style={{ backgroundImage: `url(${imageEmpat})` }}
                  className="w-full  row-span-3 h-fullrounded-lg  bg-cover"
                ></div>
              </div>
            </div>
          </div>
          <BlogSection />
          <FooterSection />
        </div>
      </div>
    </>
  );
};

export default AboutPage;
