import { BtnPrimary, Header } from "../index.js";
import { productItems } from "../../constant/index.js";
import "../../App.css";
import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "react-responsive";

const ProductSection = () => {
  const isMedium = useMediaQuery({ minWidth: "660px" });
  const isLarge = useMediaQuery({ minWidth: "1500px" });
  const isSmall = useMediaQuery({ minWidth: "320px" });

  const [slide, setSlide] = useState(1);

  useEffect(() => {
    if (isLarge) {
      setSlide(3);
    } else if (isMedium) {
      setSlide(2);
    } else {
      setSlide(1);
    }
  }, [isLarge, isMedium]);

  const textSizeDesc = isLarge
    ? "text-xl-medium"
    : isMedium
    ? "text-lg-medium"
    : "text-base-medium";

  const CardProduct = ({
    title,
    desc,
    produk,
    imgBig,
    imgSmall,
    classname,
    link,
  }) => (
    <a href={link}>
      <div
        id="product-card"
        className="bg-white w-full  overflow-hidden rounded-xl shadow-sm"
      >
        <div
          className="w-full h-[300px] bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${imgBig})` }}
        ></div>
        <div className="desc-container px-4">
          <div className="text-container pt-4 pb-6 flex flex-col gap-4">
            <span className={`product-name text-base-semibold text-[#277296]`}>
              {produk}
            </span>
            <header className="flex flex-col gap-2">
              <h1 className={` text-xl-bold text-gray-900`}>{title}</h1>
              <p className={`text-base-medium text-gray-700`}>{desc}</p>
            </header>
          </div>
        </div>
        <div className="cta-container px-4 py-2 bg-gray-100 border-t border-gray-200 flex items-center justify-between">
          <img src={imgSmall} className="logo w-[48px]" alt="" />
          <BtnPrimary
            icon="hidden"
            text="Pelajari lebih lanjut"
            classname={classname}
            link={link}
          />
        </div>
      </div>
    </a>
  );
  return (
    <div className="bg-gray-200 py-20">
      <header className=" px-5 md:px-[90px] mb-14">
        <Header
          title="Aplikasi Unggulan Bapli School"
          desc="Produk Bapli School tersedia versi Mobile Apps juga Web, memiliki  keunggulan sistem akademik pesantren / manajemen sekolah yang terintegrasi keuangan secara digital. "
          subtitle="Tentang produk-produk yang ada di baplischool"
          cta="Pelajari produk baplischool"
          center="md:text-center"
          button="md:mx-auto w-fit hidden"
          container="md:px-[140px] max-w-[1200px] mx-auto"
          buttonIcon="hidden"
        />
      </header>
      {isMedium || isSmall ? (
        <div className="px-5 md:px-[140px] lg:px-[180px]">
          <Swiper
            slidesPerView={slide}
            spaceBetween={46}
            pagination={{
              dynamicMainBullets: 1,
              dynamicBullets: true,
              paginationClass: "custom-pagination",
            }}
            modules={[Pagination]}
            className="mySwiper w-full"
          >
            {productItems.map((ele, index) => (
              <SwiperSlide className="pb-10">
                <CardProduct
                  link={ele.link}
                  imgBig={ele.imgBig}
                  imgSmall={ele.imgSmall}
                  produk={ele.produk}
                  title={ele.title}
                  desc={ele.deskripsi}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="product-container flex gap-12 w-fit mx-auto flex-wrap">
          {productItems.map((ele, index) => (
            <CardProduct
              link={ele.link}
              imgBig={ele.imgBig}
              imgSmall={ele.imgSmall}
              produk={ele.produk}
              title={ele.title}
              desc={ele.deskripsi}
              classname="w-fit"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSection;
