import React from "react";
import { BtnPrimary } from "./index.js";
import { useMediaQuery } from "react-responsive";
import { twMerge } from "tw-merge";

const Header = ({
  title,
  desc,
  subtitle,
  cta,
  container,
  center,
  button,
  buttonIcon,
}) => {
  const isMedium = useMediaQuery({ minWidth: "960px" });
  const isLarge = useMediaQuery({ minWidth: "1700px" });

  const textSizeTitle = isLarge
    ? "text-4xl-bold"
    : isMedium
    ? "text-3xl-bold"
    : "text-3xll-bold";

  const textSizeDesc = isLarge
    ? "text-xl-medium"
    : isMedium
    ? "text-lg-medium"
    : "text-base-medium";

  return (
    <div className={twMerge(`flex flex-col gap-5 ${container}`, container)}>
      <header className={twMerge(`flex flex-col gap-3 ${center}`, center)}>
        <span
          className={`${
            isMedium ? "text-lg-bold" : "text-base-bold"
          } text-brand-500 uppercase`}
        >
          {subtitle}
        </span>
        <h1 className={`${textSizeTitle}`}>{title}</h1>
      </header>
      <p
        className={`${
          isMedium ? "text-xl-medium" : "text-base-medium"
        } text-gray-600 ${center}`}
      >
        {desc}
      </p>
      <BtnPrimary text={cta} classname={button} icon={buttonIcon} />
    </div>
  );
};

export default Header;
