import {
  Information,
  Navbar,
  HeroSection,
  AboutSection,
  ProductSection,
  ReasonSection,
  TestimonialSection,
  PersuasiSection,
  BlogSection,
  FooterSection,
  FaqDropdown,
} from "../components/index.js";

const LandingPage = () => {
  return (
    <>
      <div className="overflow-hidden">
        <div className="fixed z-50 w-full transition-all duration-300">
          <Information />
          <Navbar classname="top-24" />
        </div>
        <header>
          <HeroSection />
        </header>
        <AboutSection />
        <ProductSection />
        <ReasonSection />
        {/* <TestimonialSection /> */}
        <BlogSection />
        <div className=" mx-auto mb-24">
          <FaqDropdown />
        </div>
        <PersuasiSection />
        <FooterSection />
      </div>
    </>
  );
};

export default LandingPage;
