import React from "react";
import { Sidebar } from "flowbite-react";
import { HiChartPie, HiInbox, HiViewBoards } from "react-icons/hi";

const Sidebarr = () => {
  return (
    <Sidebar aria-label="Default sidebar example" className="h-screen ">
      <Sidebar.Items className="min-h-screen">
        <Sidebar.ItemGroup>
          <Sidebar.Item className="bg-gray-300" icon={HiChartPie}>
            Konten Edutren
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
};

export default Sidebarr;
