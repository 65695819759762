import React from "react";
import { Whatsapp, Chevron } from "../assets/index.js";
import { twMerge } from "tw-merge";
import { Link } from "react-scroll";

const BtnPrimary = ({ icon, arrow, text, classname, link, scroll }) => {
  return (
    <a target="blank" href={link} className={`${classname}`}>
      <button
        className={twMerge(
          `text-white bg-brand-500
        hover:bg-brand-600
         px-5 py-3 rounded-lg  flex items-center w-full gap-2 md:w-fit ${classname}`,
          classname
        )}
      >
        <div className="whatsapp-icon flex items-center gap-1">
          <Whatsapp classname={`fill-white ${icon}`} />
          <p className="text-base-medium">{text}</p>
        </div>
        <Chevron classname="fill-white" />
      </button>
    </a>
  );
};

export default BtnPrimary;
