import React from "react";
import { smilingGirl, pattern } from "../../assets/index.js";
import { BtnSecondary } from "../index.js";
import { useMediaQuery } from "react-responsive";
import "../../App.css";
import { Link } from "react-router-dom";

const AboutSection = () => {
  const isMedium = useMediaQuery({ minWidth: "960px" });
  const isLarge = useMediaQuery({ minWidth: "1700px" });

  const textSizeDesc = isLarge
    ? "text-xl-medium"
    : isMedium
    ? "text-lg-medium"
    : "text-base-medium";

  const textSizeTitle = isLarge
    ? "text-4xl-bold"
    : isMedium
    ? "text-3xl-bold"
    : "text-3xll-bold";

  return (
    <div
      id="aboutSection"
      className="contain px-5 md:px-[140px] static py-[140px]"
    >
      <div className="about text-white w-full px-6 md:px-[56px] pt-[36px] flex flex-col sm:flex-row items-center justify-between relative rounded-xl overflow-hidden h-fit">
        <div className="text-container max-w-[720px] flex flex-col gap-8 z-10">
          <div className="flex flex-col gap-3">
            <h1 className={`${textSizeTitle}`}>
              Membuka Peluang menuju Pendidikan yang Lebih Profesional!
            </h1>
            <p className={`${isLarge ? "text-xl-medium" : "text-lg-medium"}`}>
              Temukan informasi lengkap tentang setiap aktivitas Bapli School
              beserta solusi komprehensif untuk digitalisasi manajemen sekolah
              dan sistem akademik pesantren
            </p>
          </div>
          <Link to="/about">
            <BtnSecondary
              classname="w-fit"
              text="Baca tentang Baplischool"
              icon="hidden"
            />
          </Link>
        </div>
        <img
          src={smilingGirl}
          className={`${
            isLarge ? "w-[400px]" : "w-[300px]"
          } z-10 pt-[60px] md:pt-0`}
          clas
          alt=""
        />
        <img
          src={pattern}
          className="absolute -right-56 top-0 w-full xl:max-w-[900px] max-w-[600px] hidden lg:flex"
          alt=""
          srcset=""
        />
      </div>
    </div>
  );
};

export default AboutSection;
