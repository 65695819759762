import React, { useEffect, useState } from "react";
import { AdminNav, Sidebarr } from "../../components";
import { academyData } from "../../constant";
import {
  Button,
  Checkbox,
  Label,
  ListGroup,
  Modal,
  Table,
  TextInput,
  Textarea,
} from "flowbite-react";
import { Popover } from "@headlessui/react";
import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import { HiOutlineExclamationCircle, HiHome } from "react-icons/hi";
import axios from "axios";
import { notFound } from "../../assets";
import { Toast, Breadcrumb } from "flowbite-react";

const Episode = () => {
  const [dataRaw, setDataRaw] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const { id, idBagian } = useParams();
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [dataGuru, setDataGuru] = useState([]);
  const [judulEpisode, setJudulEpisode] = useState();
  const [deskripsiEpisode, setdeskripsiEpisode] = useState();
  const [linkEpisode, setLinkEpisode] = useState();
  const [email, setEmail] = useState("");

  const closeModal = () => {
    setOpenModal((prev) => !prev);
  };

  const closeModalForm = () => {
    setOpenModalForm((prev) => !prev);
  };

  const fetchDataGuru = async () => {
    try {
      const result = await axios.get("http://localhost:3001/api/roles");
      setDataGuru(result.data.filter((ele, index) => ele._id == id));
    } catch (error) {
      console.log(error);
    }
  };

  // data fetching
  const fetchData = async () => {
    try {
      const result = await axios.get(
        `http://localhost:3001/api/role/${id}/bagian/${idBagian}/tutorial`
      );
      setDataRaw(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataGuru();
  }, []);

  const postData = async (e) => {
    e.preventDefault();

    if (!judulEpisode || !deskripsiEpisode || !linkEpisode) {
      alert("Semua field harus diisi");
      return;
    }

    const formData = new FormData();
    formData.append("judul_episode", judulEpisode);
    formData.append("deskripsi_episode", deskripsiEpisode);
    formData.append("link_episode", linkEpisode);

    const PORT = `http://localhost:3001/api/role/${id}/bagian/${idBagian}`;
    console.log(PORT);

    try {
      const result = await axios.post(PORT, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(result.data);
      fetchData();
      setOpenModalCreate((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  const updateData = async (e, idTutorial) => {
    e.preventDefault();

    console.log("halooo");
    if (!judulEpisode || !deskripsiEpisode || !linkEpisode) {
      alert("Semua field harus diisi");
      return;
    }

    const formData = new FormData();
    formData.append("judul_episode", judulEpisode);
    formData.append("deskripsi_episode", deskripsiEpisode);
    formData.append("link_episode", linkEpisode);

    const PORT = `http://localhost:3001/api/role/${id}/bagian/${idBagian}/tutorial/${idTutorial}`;
    console.log(PORT);

    try {
      const result = await axios.put(PORT, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(result.data);
      fetchData();
      setOpenModalForm((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  const deletaData = async (e, idTutorial) => {
    const PORT = `http://localhost:3001/api/role/${id}/bagian/${idBagian}/tutorial/${idTutorial}`;
    console.log(PORT);
    try {
      const hapus = await axios.delete(PORT);
      console.log(hapus);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const basePath = "/baplischool";

  return (
    <>
      <AdminNav />
      <div className="flex bg-gray-200 dark:bg-gray-900">
        <Sidebarr />

        {dataRaw.length == 0 ? (
          <>
            <div className="flex flex-col items-center mx-auto pt-20  ">
              <img src={notFound} className="w-72" alt="" srcset="" />
              <div className="flex flex-col gap-4 items-center ">
                <h1 className="text-2xl-semibold text-center text-gray-800">
                  Ups, belum ada data konten, buat yang baru yuk!
                </h1>
                <Button
                  className="mb-4"
                  onClick={() => setOpenModalCreate((prev) => !prev)}
                >
                  Tambah
                </Button>
                <Modal show={openModalCreate} size="md" popup>
                  <form onSubmit={(e) => postData(e)}>
                    <Modal.Header
                      onClick={() => setOpenModalCreate((prev) => !prev)}
                    />
                    <Modal.Body>
                      {/* create data */}
                      <div className="space-y-6">
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="judul_episode"
                              value="Judul Episode"
                            />
                          </div>
                          <TextInput
                            id="Judul Episode"
                            required
                            onChange={(e) => setJudulEpisode(e.target.value)}
                          />
                        </div>
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="deskripsi_episode"
                              value="Deskripsi Video"
                            />
                          </div>
                          <TextInput
                            id="judul_role"
                            required
                            onChange={(e) =>
                              setdeskripsiEpisode(e.target.value)
                            }
                          />
                        </div>
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="link_episode"
                              value="Link Video (Youtube Embed)"
                            />
                          </div>
                          <TextInput
                            id="link_episode"
                            required
                            onChange={(e) => setLinkEpisode(e.target.value)}
                          />
                        </div>

                        <div className="w-full">
                          <Button type="submit">Kirim</Button>
                        </div>
                        <div className="flex justify-between text-sm font-medium text-gray-500 dark:text-gray-300">
                          Not registered?&nbsp;
                          <a
                            href="#"
                            className="text-cyan-700 hover:underline dark:text-cyan-500"
                          >
                            Create account
                          </a>
                        </div>
                      </div>
                      {/* create data */}
                    </Modal.Body>
                  </form>
                </Modal>
              </div>
            </div>
          </>
        ) : (
          <div className="overflow-x-auto w-full px-8 pt-8">
            <div>
              <div
                id="breadcrumb"
                className="flex items-center justify-between"
              >
                <div>
                  <Breadcrumb
                    className="mb-2"
                    aria-label="Default breadcrumb example"
                  >
                    <Breadcrumb.Item
                      href={`${basePath}/#/admin/dashboard`}
                      icon={HiHome}
                    >
                      Data Role
                    </Breadcrumb.Item>
                    {dataGuru &&
                    dataGuru.length > 0 &&
                    dataGuru[0].judul_role ? (
                      <Breadcrumb.Item
                        href={`${basePath}/#/admin/dashboard/${id}`}
                      >
                        Bagian Role <span>{dataGuru[0].judul_role}</span>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item
                        href={`${basePath}/#/admin/dashboard/${id}`}
                      >
                        Bagian Role <span>(Judul Role Tidak Tersedia)</span>
                      </Breadcrumb.Item>
                    )}

                    <Breadcrumb.Item
                      href={`${basePath}/#/admin/dashboard/${id}/bagian/${idBagian}/tutorial`}
                    >
                      Data Episode
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <h1 className="text-2xl font-semibold mb-10 font-Jakarta text-gray-900">
                    Data Episode{" "}
                    {dataGuru &&
                    dataGuru.length > 0 &&
                    dataGuru[0].judul_role ? (
                      <span className="font-extrabold">
                        Role <span>{dataGuru[0].judul_role}</span>
                      </span>
                    ) : (
                      <span className="font-extrabold">
                        (Judul Role Tidak Tersedia)
                      </span>
                    )}{" "}
                    Edutren
                  </h1>
                </div>
                <Button
                  className="mb-4"
                  onClick={() => setOpenModalCreate((prev) => !prev)}
                >
                  Tambah
                </Button>
              </div>
              <Modal show={openModalCreate} size="md" popup>
                <form onSubmit={(e) => postData(e)}>
                  <Modal.Header
                    onClick={() => setOpenModalCreate((prev) => !prev)}
                  />
                  <Modal.Body>
                    {/* create data */}
                    <div className="space-y-6">
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="judul_episode"
                            value="Judul Episode"
                          />
                        </div>
                        <TextInput
                          id="Judul Episode"
                          required
                          onChange={(e) => setJudulEpisode(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="deskripsi_episode"
                            value="Deskripsi Video"
                          />
                        </div>
                        <TextInput
                          id="judul_role"
                          required
                          onChange={(e) => setdeskripsiEpisode(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="link_episode"
                            value="Link Video (Youtube Embed)"
                          />
                        </div>
                        <TextInput
                          id="link_episode"
                          required
                          onChange={(e) => setLinkEpisode(e.target.value)}
                        />
                      </div>

                      <div className="w-full">
                        <Button type="submit">Kirim</Button>
                      </div>
                      <div className="flex justify-between text-sm font-medium text-gray-500 dark:text-gray-300">
                        Not registered?&nbsp;
                        <a
                          href="#"
                          className="text-cyan-700 hover:underline dark:text-cyan-500"
                        >
                          Create account
                        </a>
                      </div>
                    </div>
                    {/* create data */}
                  </Modal.Body>
                </form>
              </Modal>
            </div>
            <Table hoverable>
              <Table.Head>
                <Table.HeadCell>No</Table.HeadCell>
                <Table.HeadCell>Judul Video</Table.HeadCell>
                <Table.HeadCell>Deskripsi Video</Table.HeadCell>
                <Table.HeadCell> Video</Table.HeadCell>
                <Table.HeadCell> Aksi</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {dataRaw.map((ele, index) => (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>{ele.judul_episode}</Table.Cell>
                    <Table.Cell>
                      <h1>{ele.deskripsi_episode}</h1>
                    </Table.Cell>
                    <Table.Cell>
                      <iframe
                        className="w-48 aspect-video"
                        src={ele.link_episode}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </Table.Cell>
                    <Table.Cell>
                      <Popover className="relative">
                        <Popover.Button>
                          <a
                            href="#"
                            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          >
                            Edit
                          </a>
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 right-52">
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <div className="grid grid-cols-2">
                              <ListGroup className="w-48 shadow-2xl shadow-gray-400">
                                <ListGroup.Item
                                  onClick={() => setOpenModalForm(true)}
                                >
                                  Edit Episode
                                </ListGroup.Item>
                                {/* update data */}

                                <Modal show={openModalForm} size="md" popup>
                                  <Modal.Header
                                    onClick={() => closeModalForm()}
                                  />
                                  <form
                                    onSubmit={(e) => updateData(e, ele._id)}
                                  >
                                    <Modal.Body>
                                      <div className="space-y-6">
                                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                          Edit Episode
                                        </h3>
                                        <div>
                                          <div className="mb-2 block">
                                            <Label
                                              htmlFor="episode"
                                              value="Masukkan Link Episode Baru"
                                            />
                                          </div>
                                          <TextInput
                                            id="episode"
                                            placeholder="Episode "
                                            onChange={(event) =>
                                              setLinkEpisode(event.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div>
                                          <div className="mb-2 block">
                                            <Label
                                              htmlFor="episode"
                                              value="Masukkan Judul Episode Baru"
                                            />
                                          </div>
                                          <TextInput
                                            id="episode"
                                            placeholder="Episode "
                                            // value={dataKonten[index].title_episode}
                                            onChange={(event) =>
                                              setJudulEpisode(
                                                event.target.value
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                        <div>
                                          <div className="mb-2 block">
                                            <Label
                                              htmlFor="password"
                                              value="Deksripsi Video"
                                            />
                                          </div>

                                          <Textarea
                                            id="comment"
                                            placeholder="Deskripsi Video..."
                                            required
                                            onChange={(e) =>
                                              setdeskripsiEpisode(
                                                e.target.value
                                              )
                                            }
                                            rows={4}
                                          />
                                        </div>
                                        <div className="flex justify-between">
                                          <div className="flex items-center gap-2">
                                            <Checkbox id="remember" />
                                            <Label htmlFor="remember">
                                              Remember me
                                            </Label>
                                          </div>
                                          <a
                                            href="#"
                                            className="text-sm text-cyan-700 hover:underline dark:text-cyan-500"
                                          >
                                            Lost Password?
                                          </a>
                                        </div>
                                        <div className="w-full">
                                          <Button type="submit">
                                            Log in to your account
                                          </Button>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </form>
                                </Modal>

                                {/* update data */}
                                <button
                                  type="submit"
                                  className="w-full cursor-pointer"
                                >
                                  <ListGroup.Item
                                    onClick={() =>
                                      setOpenModal((prev) => !prev)
                                    }
                                  >
                                    Hapus Episode
                                    <Modal
                                      show={openModal}
                                      size="md"
                                      onClick={() => closeModal}
                                      popup
                                    >
                                      <Modal.Header
                                        onClick={() => closeModal}
                                      />

                                      <Modal.Body>
                                        <div className="text-center">
                                          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                            Are you sure you want to delete this
                                            product?
                                          </h3>
                                          <div className="flex justify-center gap-4">
                                            <Button
                                              color="failure"
                                              type="submit"
                                              onClick={(e) =>
                                                deletaData(e, ele._id)
                                              }
                                            >
                                              Iya, Hapus
                                            </Button>
                                            <Button
                                              color="gray"
                                              onClick={() => closeModal}
                                            >
                                              No, cancel
                                            </Button>
                                          </div>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </ListGroup.Item>
                                </button>
                              </ListGroup>
                            </div>

                            <img src="/solutions.jpg" alt="" />
                          </motion.div>
                        </Popover.Panel>
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default Episode;
