import { Cta, Navbar } from "../components";
import { Tab } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { linkIcon, waIcon, waIconn } from "../assets/index.js";
import { useParams } from "react-router-dom";
import axios from "axios";

const MainCourse = () => {
  const { id, idBagian, index } = useParams();
  const [bagian, setBagian] = useState({});
  let [isOpen, setIsOpen] = useState(true);
  const [dataRaw, setDataRaw] = useState([]);

  const onClickFunction = (event) => {
    event.preventDefault();
    window.history.back();
  };

  const isSmall = useMediaQuery({ maxWidth: "768px" });

  function copyToClipboard() {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        alert("URL berhasil disalin ke clipboard!");
      })
      .catch((err) => {
        console.error("Gagal menyalin URL: ", err);
      });
  }
  const fetchDataBagian = async () => {
    try {
      const result = await axios.get(
        `http://localhost:3001/api/role/${id}/bagian`
      );
      setBagian(result.data[index]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    fetchDataBagian();
  }, []);

  const fetchData = async () => {
    try {
      const result = await axios.get(
        `http://localhost:3001/api/role/${id}/bagian/${idBagian}/tutorial`
      );
      setDataRaw(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ButtonSmall = ({ icon, text, method }) => (
    <button
      onClick={method}
      className="text-small-medium text-gray-700 flex items-center gap-2 px-3 py-2 bg-gray-200 rounded-md hover:bg-gray-400 transition-all duration-100 active:bg-gray-300"
    >
      <img src={icon} alt="" srcset="" />
      {text}
    </button>
  );
  return (
    <>
      <div className="fixed z-50 w-full transition-all duration-300">
        <Navbar classname="top-14" />
      </div>
      <div className="main px-5 md:px-[160px] pt-28 md:pt-32 flex items-start gap-5 justify-between">
        <Tab.Group>
          <div className="aside">
            <div className="mb-4">
              <Cta
                onClickFunction={onClickFunction}
                text="Pelajari Bagian Lainnya"
                arrowOrder="order-first rotate-180"
              />
            </div>
            <div className="list">
              <div className="title-header flex flex-col gap-1 mb-6">
                <h1 className="text-xl-bold text-gray-900z">
                  {bagian ? `${bagian.judul_bagian}` : "Data tidak ada"}
                </h1>
                <div className="bagian flex items-center gap-2 text-base-medium text-gray-600">
                  <span className="">
                    Bagian {bagian ? `${bagian.nomor_bagian}` : ""}
                  </span>
                  <div className="dotted w-1 h-1 rounded-full bg-gray-600"></div>
                  <span>{dataRaw.length} Pembelajaran</span>
                </div>
              </div>
              <div className="list-container flex max-w-[300px] w-full">
                <Tab.List className="flex flex-col">
                  {dataRaw.map((ele, index) => (
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <div className="flex items-center gap-2 py-2 cursor-pointer focus:ring-0">
                          <article
                            className={`${
                              selected
                                ? "bg-[#FF3C4D]  focus:ring-0"
                                : "bg-gray-500"
                            } ui-selected:bg-blue-500 w-8 h-8 rounded font-Jakarta flex items-center   justify-center text-small-bold text-white`}
                          >
                            {index + 1}
                          </article>
                          <span
                            className={`${
                              selected ? "text-[#FF3C4D]" : "text-gray-500"
                            } font-Jakarta text-lg  font-medium `}
                          >
                            {ele.judul_episode}
                          </span>
                        </div>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <div className="w-full h-[0.5px] bg-gray-200"></div>
            </div>
          </div>

          <div className="content flex max-w-[600px]">
            <Tab.Panels>
              {dataRaw.map((ele, index) => (
                <Tab.Panel>
                  <div className="content flex flex-col gap-6">
                    <iframe
                      className="w-[600px] aspect-video"
                      src={ele.link_episode}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <div className="content-desc">
                      <div className="text-containerf flex flex-col gap-6">
                        <div className="header flex flex-col gap-3">
                          <h1
                            className={`${
                              isSmall ? "text-xl-bold" : "text-2xl-bold"
                            }`}
                          >
                            {ele.judul_episode}
                          </h1>
                          <p
                            className={`${
                              isSmall ? "text-base-medium" : "text-lg-medium"
                            }`}
                          >
                            {ele.deskripsi_episode}
                          </p>
                        </div>
                        <div className="other-menu flex flex-col gap-3">
                          <span className="text-small-medium text-gray-800">
                            MENU LAINNYA
                          </span>
                          <div className="menu-cta flex items-center gap-2">
                            <ButtonSmall
                              text="Bagikan Video"
                              method={copyToClipboard}
                              icon={linkIcon}
                            />
                            <ButtonSmall
                              text="Masih Bingung? Tanya admin"
                              icon={waIcon}
                            />
                            <button onClick={copyToClipboard}>Salin URL</button>
                          </div>
                        </div>
                      </div>
                      <div className="cta-arrow"></div>
                    </div>
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default MainCourse;
