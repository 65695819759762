import {
  personEdutren,
  personDigitren,
  personSimakdis,
  logoDigitren,
  logoSimakdis,
  logoEdutren,
  waIcon,
  emailIcon,
  instagramIcon,
  twitterIcon,
  megaDigitren,
  megaSimakdis,
  megaEdutren,
  iconAbsen,
  iconData,
  iconSearch,
  iconMurid,
  iconBlog,
  iconInstagram,
  iconTiktok,
  iconYoutube,
  edutrenBadge,
  simakdisBadge,
  digitrenBadge,
  digitrenDisable,
  simakdisDisable,
  kangHilmi,
  pakNur,
  pakAkka,
  tiktok,
  whatsapp,
} from "../assets/index.js";

export const navbarItems = [
  {
    id: 1,
    item: "Produk",
    link: "",
    status: true,
    subitems: [
      {
        id: 1,
        title: "Simakdis",
        desc: "Solusi untuk manajemen sekolah",
        img: megaSimakdis,
        link: "https://www.simakdis.id/login",
      },
      {
        id: 2,
        title: "Edutren",
        desc: "Solusi untuk manajemen sekolah",
        img: megaEdutren,
        link: "https://www.edutren.id/",
      },
      {
        id: 3,
        title: "Digitren",
        desc: "Solusi untuk manajemen sekolah",
        img: megaDigitren,
        link: "https://play.google.com/store/apps/details?id=id.bapli.smartedu&hl=id&gl=US",
      },
    ],
  },

  {
    id: 2,
    item: "Solusi",
    link: "",
    status: true,
    subitems: [
      {
        id: 1,
        title: "Simakdis",
        desc: "Solusi untuk manajemen sekolah",
        img: megaDigitren,
        link: "",
      },
      {
        id: 2,
        title: "Edutren",
        desc: "Solusi untuk manajemen sekolah",
        img: megaEdutren,
        link: "",
      },
      {
        id: 3,
        title: "Digitren",
        desc: "Solusi untuk manajemen sekolah",
        img: megaDigitren,
        link: "",
      },
    ],
  },
  {
    id: 3,
    item: "Informasi",
    link: "",
    status: true,
    subitems: [
      {
        id: 1,
        title: "Simakdis",
        desc: "Solusi untuk manajemen sekolah",
        img: megaDigitren,
        link: "",
      },
      {
        id: 2,
        title: "Edutren",
        desc: "Solusi untuk manajemen sekolah",
        img: megaEdutren,
        link: "",
      },
      {
        id: 3,
        title: "Digitren",
        desc: "Solusi untuk manajemen sekolah",
        img: megaDigitren,
        link: "",
      },
    ],
  },

  {
    id: 6,
    item: "Tentang Kami",
    link: "",
    status: false,
    subitems: [],
  },
];

export const productItems = [
  {
    id: 1,
    produk: "Digitren",
    title: "Edutren : Edukasi Digital Pesantren",
    deskripsi:
      "Optimasi sistem pendidikan sekolah dari mulai pembayaran SPP Bulanan. ",
    imgBig: personDigitren,
    badge: edutrenBadge,
    imgSmall: logoDigitren,
    link: "https://www.edutren.id/",
  },
  {
    id: 2,
    produk: "Simakdis",
    title: "Edutren : Edukasi Digital Pesantren",
    deskripsi:
      "Optimasi sistem pendidikan sekolah dari mulai pembayaran SPP Bulanan. ",
    badge: simakdisBadge,
    imgBig: personSimakdis,
    imgSmall: logoSimakdis,
    link: "https://www.simakdis.id/login",
  },
  {
    id: 3,
    produk: "Edutren",
    title: "Edutren : Edukasi Digital Pesantren",
    deskripsi:
      "Optimasi sistem pendidikan sekolah dari mulai pembayaran SPP Bulanan. ",
    imgBig: personEdutren,
    imgSmall: logoEdutren,
  },
];

export const footerItems = [
  {
    id: 1,
    title: "Navigasi",
    menu: [
      {
        id: 1,
        list: "Produk",
        icon: "",
        link: "/",
      },
      {
        id: 2,
        list: "Fitur",
        icon: "",
        link: "/",
      },
      {
        id: 3,
        list: "Informasi",
        icon: "",
        link: "/",
      },
      {
        id: 4,
        list: "Academy",
        icon: "",
        link: "/",
      },
      // {
      //   id: 5,
      //   list: "Harga",
      //   icon: "",
      //   link: "/",
      // },
      {
        id: 6,
        list: "Tentang Kami",
        icon: "",
        link: "/",
      },
      {
        id: 6,
        list: "Hak Cipta Foto",
        icon: "",
        link: "https://linktr.ee/photoscopyright?utm_source=linktree_admin_share",
      },
    ],
  },
  {
    id: 2,
    title: "Produk",
    menu: [
      {
        id: 1,
        list: "Edutren",
        icon: "",
        link: "/",
      },
      {
        id: 2,
        list: "Simakdis",
        icon: "",
        link: "/",
      },
      {
        id: 3,
        list: "Digitren",
        icon: "",
        link: "/",
      },
    ],
  },
  {
    id: 3,
    title: "Kontak Kami",
    menu: [
      {
        id: 1,
        list: "082240040640",
        icon: whatsapp,
        link: "",
      },
      {
        id: 2,
        list: "baplischool@gmail.com",
        icon: emailIcon,
        link: "/",
      },
    ],
  },
  {
    id: 4,
    title: "Sosial Media",
    menu: [
      {
        id: 1,
        list: "bapli4school",
        icon: instagramIcon,
        link: "https://www.instagram.com/bapli4school?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D",
      },
      {
        id: 2,
        list: "bapliid",
        icon: tiktok,
        link: "https://www.tiktok.com/@bapliid",
      },
    ],
  },
];

export const megaMenuItems = [];

export const solusiItems = [
  {
    id: 1,
    title: "School growth",
    desc: "Solusi kemajuan sekolah  ",
    img: iconBlog,
  },
  {
    id: 2,
    title: "Instructional Media growth",
    desc: "Solusi kemajuan sistem pendidikan ",
    img: iconData,
  },
  {
    id: 3,
    title: "Business growth",
    desc: "Solusi kemajuan bisnis anda ",
    img: iconMurid,
  },
  {
    id: 4,
    title: "Morals growth",
    desc: "Solusi kemajuan peserta didik",
    img: iconSearch,
  },
];

export const informasiItems = [
  {
    id: 1,
    title: "Blog",
    desc: "Kumpulan artikel Baplischool",
    img: iconBlog,
    link: "https://mediana.id/ekonomi/348/5-ide-ternak-yang-bisa-dilakukan-di-rumah-dengan-cuan-menggiurkan",
  },
  {
    id: 2,
    title: "Instagram",
    desc: "Follow Instagramnya @bapli4school",
    img: iconInstagram,
    link: "https://www.instagram.com/bapli4school?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
  },
  {
    id: 3,
    title: "Tik-Tok",
    desc: "Kepoin Tik-Toknya",
    img: iconTiktok,
    link: "https://www.tiktok.com/@bapliid",
  },
  {
    id: 4,
    title: "Youtube",
    desc: "Subscribe channel Youtube Bapli School",
    img: iconYoutube,
    link: "https://www.youtube.com/@baplischool",
  },
];

export const produkItems = [
  {
    id: 1,
    title: "Simakdis",
    desc: "Solusi untuk sistem akademik",
    img: megaSimakdis,
    link: "https://www.simakdis.id/login",
  },
  {
    id: 2,
    title: "Edutren",
    desc: "Solusi untuk manajemen sekolah",
    img: megaEdutren,
    link: "https://www.edutren.id/",
  },
  {
    id: 3,
    title: "Digitren",
    desc: "Cashless pesantren",
    img: megaDigitren,
    link: "https://play.google.com/store/apps/details?id=id.bapli.smartedu&hl=id&gl=US",
  },
];

export const pricingItems = [
  {
    id: 1,
    header: "Starter",
    subHeader: " For production applications with the option to scale.",
    price: "Rp 1.499.000",
    benefitItems: [
      {
        id: 1,
        item: "Dukungan Badge Centang hijau WhatsApp",
      },
      {
        id: 2,
        item: "Masking akun WhatsApp",
      },
      {
        id: 3,
        item: "Unlimited Quota agent",
      },
      {
        id: 4,
        item: "Alokasi agen otomatis",
      },
      {
        id: 5,
        item: "Autosave riwayat percakapan",
      },
      {
        id: 6,
        item: "Template pesan terkustomisasi",
      },
    ],
  },
  {
    id: 2,
    header: "Enterprise",
    subHeader: " For production applications with the option to scale.",
    price: "Rp 1.499.000",
    benefitItems: [
      {
        id: 1,
        item: "Dukungan Badge Centang hijau WhatsApp",
      },
      {
        id: 2,
        item: "Masking akun WhatsApp",
      },
      {
        id: 3,
        item: "Unlimited Quota agent",
      },
      {
        id: 4,
        item: "Alokasi agen otomatis",
      },
      {
        id: 5,
        item: "Autosave riwayat percakapan",
      },
      {
        id: 6,
        item: "Template pesan terkustomisasi",
      },
    ],
  },
  {
    id: 3,
    header: "Custom",
    subHeader: " For production applications with the option to scale.",
    price: "Rp 1.499.000",
    benefitItems: [
      {
        id: 1,
        item: "Dukungan Badge Centang hijau WhatsApp",
      },
      {
        id: 2,
        item: "Masking akun WhatsApp",
      },
      {
        id: 3,
        item: "Unlimited Quota agent",
      },
      {
        id: 4,
        item: "Alokasi agen otomatis",
      },
      {
        id: 5,
        item: "Autosave riwayat percakapan",
      },
      {
        id: 6,
        item: "Template pesan terkustomisasi",
      },
    ],
  },
];

export const faqList = [
  {
    id: 1,
    title: "Apa Itu Edutren dan Digitren ?",
    desc: " EduTren merupakan aplikasi Manajemen Akademik sekolah yang dikembangkan sesuai dengan kebutuhan manajemen pesantren serta dilengkapi dengan Fasilitas e-learning, jadwal pengajaran , data santri , data Asatidz hingga informasi keuangan pesantren , EduTran dibuat dengan Flatform web based dengan tampilan user friendly yang memudahkan user untuk mengoperasikannya.",
  },
  {
    id: 2,
    title: "Apa Itu SIMAKDIS ?",
    desc: "Simakdis adalah sebuah sistem informasi akademik dan digitalisasi sekolah yang dikembangkan untuk memenuhi kebutuhan sekolah-sekolah pada umumnya",
  },
  {
    id: 3,
    title: "Apa perbedaan Edutren dan Digitren?",
    desc: "Edutren dan Digitren merupakan aplikasi yang dikembangkan dalam dua flatform yang berbeda Edutren dengan flatform Webbased dan Digitren dikembangkan dalam flatform Android, namun keduanya merupakan satu kesatuan sitem yang sama.",
  },
  {
    id: 4,
    title:
      "Apakah Digitren memiliki izin dalam pengeloaan transaksi perbankan?",
    desc: "Digitren hanya menampilkan informasi dan melakukan pencatatan terkait transaksional yang terjadi di lingkungan pesantren, sehingga tidak memerlukan perizinan dari BI OJK dan regulator lainnya.",
  },
];

export const academyData = [
  {
    id: 1,
    title_role: "Role Guru",
    data_bagian: [
      {
        id: 1,

        nomor_bagian: 1,
        title_bagian: "Manajemen Data Murid",
        desc_bagian:
          "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
        data_tutorial: {
          id: 1,
          title_kursus: "Import dengan Excel dan bagiannya",
          data_konten: [
            {
              id: 1,
              link_episode:
                "https://www.youtube.com/embed/r4BacY1Z-hI?si=uG_jzcjIPV06mTcS",
              title_episode: "Import dengan Excel dan bagiannya",
              desc_video:
                "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
              link_video: "https://nextui.org/docs/components/popover",
            },
            {
              id: 2,
              link_episode: "https://youtu.be/r4BacY1Z-hI?si=JR-hr8UCRib3K-_L",
              title_episode: "Export Video",
              desc_video:
                "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
              link_video: "https://nextui.org/docs/components/popover",
            },
            {
              id: 3,
              link_episode:
                "https://www.youtube.com/embed/r4BacY1Z-hI?si=uG_jzcjIPV06mTcS",
              title_episode: "Export Data Murid",
              desc_video:
                "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
              link_video: "https://nextui.org/docs/components/popover",
            },
          ],
        },
      },
      {
        id: 2,

        nomor_bagian: 2,
        title_bagian: "Manajemen Data Guru",
        desc_bagian:
          "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
        data_tutorial: {
          id: 1,
          title_kursus: "Import dengan Excel dan bagiannya",
          data_konten: [
            {
              id: 1,
              link_episode:
                "https://www.youtube.com/embed/r4BacY1Z-hI?si=uG_jzcjIPV06mTcS",
              title_episode: "Import dengan Excel dan bagiannya",
              desc_video:
                "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
              link_video: "https://nextui.org/docs/components/popover",
            },
            {
              id: 2,
              link_episode:
                "https://www.youtube.com/embed/6UsxUcRnb-Q?si=V_pC7scFAjRaOylC",
              title_episode: "Export Video",
              desc_video:
                "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
              link_video: "https://nextui.org/docs/components/popover",
            },
            {
              id: 3,
              link_episode:
                "https://www.youtube.com/live/b76Xzq7q9-E?si=1XdMNxQ5p9Jee84z",
              title_episode: "Export Data Murid",
              desc_video:
                "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
              link_video: "https://nextui.org/docs/components/popover",
            },
          ],
        },
      },
    ],
  },
  {
    id: 2,
    title_role: "Role Murid",
    data_bagian: [
      {
        id: 1,
        nomor_bagian: 1,
        title_bagian: "Manajemen Data Sekolah",
        desc_bagian:
          "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
        data_tutorial: [
          {
            id: 1,
            title_kursus: "Import dengan Excel dan bagiannya",
            data_konten: [
              {
                id: 1,
                link_episode:
                  "https://www.youtube.com/embed/r4BacY1Z-hI?si=uG_jzcjIPV06mTcS",
                title_episode: "Import dengan Excel dan bagiannya",
                desc_video:
                  "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
                link_video: "https://nextui.org/docs/components/popover",
              },
              {
                id: 2,
                link_episode:
                  "https://www.youtube.com/embed/r4BacY1Z-hI?si=uG_jzcjIPV06mTcS",
                title_episode: "Export Video",
                desc_video:
                  "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
                link_video: "https://nextui.org/docs/components/popover",
              },
              {
                id: 3,
                link_episode:
                  "https://www.youtube.com/embed/r4BacY1Z-hI?si=uG_jzcjIPV06mTcS",
                title_episode: "Export Data Murid",
                desc_video:
                  "Mulai pelajari aplikasi Edutren. Ada berbagai pilihan pembelajaran role, dari guru, hingga orang tua murid!",
                link_video: "https://nextui.org/docs/components/popover",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const dataAplikasi = [
  {
    id: 1,
    title: "Edutren (Edukasi Pesantren)",
    badge: edutrenBadge,
    deskripsi:
      "Belajar seluruh fungsi fitur Edutren melalui video tutorial singkat hingga sangat mudah di fahami",
    imgCover: personEdutren,
  },
  {
    id: 2,
    title: "Digitren (Digitalisasi Pesantren)",
    badge: digitrenBadge,
    deskripsi:
      "Belajar seluruh fungsi fitur Edutren melalui video tutorial singkat hingga sangat mudah di fahami",
    imgCover: digitrenDisable,
  },
  {
    id: 3,
    title: "Simakdis (Sistem Informasi Akademik & Digitalisasi Sekolah)",
    badge: simakdisBadge,
    deskripsi:
      "Belajar seluruh fungsi fitur Edutren melalui video tutorial singkat hingga sangat mudah di fahami",
    imgCover: simakdisDisable,
  },
];

export const dataTestimonial = [
  {
    id: 1,
    komentar:
      "Alhamdulillah, sebagai pengelola keuangan pondok pesantren, kami merasa bangga dengan hadirnya aplikasi Edutren. Aplikasi ini benar-benar membantu kami dalam melakukan pengeloaan keuangan yang rumit menjadi terasa simpel dan efektif. Selain itu, aplikasi Edutren dapat diakses secara realtime sehingga membantu kami dalam memberikan umpan balik kepada orang tua santri, Terimakasih sudah menghadirkan Edutren menjadi solusi pendidikan yang inovatif ",
    nama: "Muhammad Hilmi, S.Ak",
    role: "Bendahara Direktorat Pendidikan Idrisiyyah",
    img: kangHilmi,
  },
  {
    id: 2,
    komentar:
      "Kami selaku pengelola lembaga pendidikan Pesantren merasa sangat terbantu dengan adanya aplikasi Edutren.   Lembaga kami yang semula serba manual dan belum terdigitalisasi, kini menjadi lebih baik dari sisi pengadministrasian dan pengelolaan manajemennya. Selain itu Edutren juga telah mendorong pondok kami untuk lebih terdigitalisasi Semoga Edutren semakin dipercaya oleh lembaga lembaga pendidikan lainnya agar semakin banyak lagi yang merasakan manfaat aplikasi Digitren yang luar biasa ini ",
    nama: "Ahmad Tazakka",
    role: "Pimpinan Ponpes Kampoeng Quran Cendekia",
    img: pakAkka,
  },
  {
    id: 3,
    komentar:
      "Saya sangat bersyukur ada layanan seperti Edutren Web ini yang sangat membantu sekali dalam hal pengembangan Akademik Pesantren,Selain karena simple dan 100% mudah digunakan. Edutren Web ini sangat beragam manfaat, Salah satunya saya menyukai fitur pendaftaran, karena sebagai tim administrasi sangat memudahkan pengumpulan data administrasi yang dibutuhkan oleh lembaga. Saya sangat setuju apabila Edutren ini digunakan oleh banyak pesantren untuk pengembangan sistem akademik karena saat ini Pesantren pun dituntutagar dapat melek dengan dunia digital.",
    nama: "Nur Alam Ullumuddin Zuhri",
    role: "Wakil Mudir 2 - Ma'had Aly Idrisiyyah ",
    img: pakNur,
  },
];

export const dataReasonSatu = [
  {
    id: 1,
    title: "Mudah di akses",
    desc: "Edutren merupakan Aplikasi berbasis Web, dapat diakses mudah melalui Internet",
  },
  {
    id: 2,
    title: "Sistem Akademik",
    desc: "Manajemen sekolah, raport, E-Learning hingga video conference",
  },
];

export const dataReasonDua = [
  {
    id: 1,
    title: "Sistem Keuangan",
    desc: "Manajemen biaya SPP, seragam hingga laporan harian keuangan pesantren",
  },
  {
    id: 2,
    title: "Biaya Hemat",
    desc: "Lebih hemat biaya untuk manajemen sistem sekolah.",
  },
];
