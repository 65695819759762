import { Flowbite } from "flowbite-react";
import {
  Table,
  ListGroup,
  Modal,
  Label,
  TextInput,
  FileInput,
  Button,
} from "flowbite-react";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Sidebarr, AdminNav } from "../../components";
import axios from "axios";
import {
  HiOutlineExclamationCircle,
  HiCheck,
  HiExclamation,
  HiX,
  HiHome,
} from "react-icons/hi";
import { notFound } from "../../assets";
import { Toast, Breadcrumb } from "flowbite-react";

const Dashboard = () => {
  const [file, setFile] = useState(null);
  const [judulRole, setJudulRole] = useState("");
  const [image, setImage] = useState(null);
  const [dataRaw, setDataRaw] = useState([]);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleFileChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  // function testing
  const closeModalForm = () => {
    setOpenModalForm((prev) => !prev);
  };

  // data fethcing post
  const fetchData = async () => {
    try {
      const result = await axios.get("http://localhost:3001/api/roles");
      setDataRaw(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const deleteData = async (e, id) => {
    e.preventDefault();
    try {
      const result = await axios.delete(`http://localhost:3001/api/role/${id}`);
      console.log(result.status);
    } catch (error) {
      console.error(error);
    }
    fetchData();
  };

  const updateData = async (e, id) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("image", file);
    formData.append("judul_role", judulRole);
    try {
      const result = await axios.put(
        `http://localhost:3001/api/role/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(result);
      fetchData();
      closeModalForm();
    } catch (error) {
      console.log(error);
    }
  };

  const postData = async (e) => {
    e.preventDefault();

    // form data
    const formData = new FormData();
    formData.append("image", file);
    formData.append("judul_role", judulRole);

    try {
      const result = await axios.post(
        "http://localhost:3001/api/role",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(result.data);
      setOpenModalCreate((prev) => !prev);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const basePath = "/baplischool";

  // components
  const FileUpload = () => {
    return (
      <div onChange={handleFileChange} className="w-full">
        <div className="flex w-full items-center justify-center">
          <Label
            htmlFor="dropzone-file"
            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
            <FileInput id="dropzone-file" className="hidden" />
          </Label>
        </div>
      </div>
    );
  };

  return (
    <Flowbite>
      <div className="min-h-screen bg-gray-200 dark:bg-gray-900">
        <AdminNav />
        <div className="flex">
          <Sidebarr />

          {/* toast */}

          {/* modal pop up content */}
          {dataRaw.length == 0 ? (
            <div className="flex flex-col items-center mx-auto pt-20  ">
              <img src={notFound} className="w-72" alt="" srcset="" />
              <div className="flex flex-col gap-4 items-center ">
                <h1 className="text-2xl-semibold text-center text-gray-800">
                  Ups, belum ada data konten, buat yang baru yuk!
                </h1>
                <Button
                  className="mb-4"
                  onClick={() => setOpenModalCreate((prev) => !prev)}
                >
                  Tambah Konten
                </Button>
                {/* modal uploads empty */}
                <Modal show={openModalCreate} size="md" popup>
                  <form onSubmit={(e) => postData(e)}>
                    <Modal.Header
                      onClick={() => setOpenModalCreate((prev) => !prev)}
                    />
                    <Modal.Body>
                      <div className="space-y-6">
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="nomor_bagian"
                              value="Upload Thumbnail"
                            />
                          </div>
                          <div className="flex flex-col gap-3  items-end">
                            <div className="h-64 w-full">
                              {image ? <img src={image} /> : <FileUpload />}
                            </div>
                            <label
                              for="actual-btn"
                              className={`cursor-pointer font-Jakarta text-sm text-gray-700 px-3 py-2 rounded-lg border border-gray-500 ${
                                file ? "block" : "hidden"
                              }`}
                              color="gray"
                            >
                              Ubah Gambar
                            </label>
                            <input
                              type="file"
                              className="font-Jakarta text-sm bg-brand-500 py-2 px-4 rounded"
                              id="actual-btn"
                              onChange={handleFileChange}
                              hidden
                            />
                          </div>
                        </div>
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="nomor_bagian"
                              value="Masukkan Judul Baru"
                            />
                          </div>
                          <TextInput
                            id="nomor_bagian"
                            required
                            onChange={(e) => setJudulRole(e.target.value)}
                          />
                        </div>

                        <div className="w-full">
                          <Button type="submit">Tambah Konten</Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </form>
                </Modal>
                {/* modal upload empty */}
              </div>
            </div>
          ) : (
            <div className="overflow-x-auto w-full px-8 pt-8">
              <div
                id="breadcrumb"
                className="flex items-center justify-between"
              >
                <div>
                  <Breadcrumb
                    className="mb-2"
                    aria-label="Default breadcrumb example"
                  >
                    <Breadcrumb.Item
                      href={`/${basePath}/#/admin/dashboard`}
                      icon={HiHome}
                    >
                      Data Role
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <h1 className="text-2xl font-bold mb-10 font-Jakarta text-gray-900">
                    Data Konten Role Edutren
                  </h1>
                </div>
                <Button onClick={() => setOpenModalCreate((prev) => !prev)}>
                  Tambah Konten
                </Button>
              </div>
              {/* modal uploads  */}
              <Modal show={openModalCreate} size="md" popup>
                <form onSubmit={(e) => postData(e)}>
                  <Modal.Header
                    onClick={() => setOpenModalCreate((prev) => !prev)}
                  />
                  <Modal.Body>
                    <div className="space-y-6">
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="nomor_bagian"
                            value="Upload Thumbnail"
                          />
                        </div>
                        <div className="flex flex-col gap-3  items-end">
                          <div className="h-64 w-full">
                            {image ? <img src={image} /> : <FileUpload />}
                          </div>
                          <label
                            for="actual-btn"
                            className={`cursor-pointer font-Jakarta text-sm text-gray-400 px-3 py-2 rounded-lg border border-gray-400 ${
                              file ? "block" : "hidden"
                            }`}
                            color="gray"
                          >
                            Ubah Gambar
                          </label>
                          <input
                            type="file"
                            className="font-Jakarta text-sm bg-brand-500 py-2 px-4 rounded"
                            id="actual-btn"
                            onChange={handleFileChange}
                            hidden
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="nomor_bagian"
                            value="Masukkan Judul Baru"
                          />
                        </div>
                        <TextInput
                          id="nomor_bagian"
                          required
                          onChange={(e) => setJudulRole(e.target.value)}
                        />
                      </div>

                      <div className="w-full">
                        <Button type="submit">Tambah Konten</Button>
                      </div>
                    </div>
                  </Modal.Body>
                </form>
              </Modal>
              {/* modal upload  */}
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>No</Table.HeadCell>
                  <Table.HeadCell>Thumbnail</Table.HeadCell>
                  <Table.HeadCell>Judul Role</Table.HeadCell>
                  <Table.HeadCell>Jumlah Bagian</Table.HeadCell>
                  <Table.HeadCell>Aksi</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {dataRaw.map((ele, index) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {index + 1}
                      </Table.Cell>
                      <Table.Cell>
                        <img
                          src={`http://localhost:3001/api/getImage/${ele.image_role}`}
                          alt="image thumbnail"
                          className=" w-24 rounded-lg"
                        />
                      </Table.Cell>
                      <Table.Cell>{ele.judul_role}</Table.Cell>
                      <Table.Cell>{ele.data_bagian.length} Bagian</Table.Cell>
                      <Table.Cell>
                        <Popover className="relative">
                          <Popover.Button>
                            <a
                              href="#"
                              className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                            >
                              Edit
                            </a>
                          </Popover.Button>

                          <Popover.Panel className="absolute z-10 right-52">
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <div className="grid grid-cols-2">
                                <ListGroup className="w-48 shadow-2xl shadow-gray-400">
                                  <Link to={`/admin/dashboard/${ele._id}`}>
                                    <ListGroup.Item href="#">
                                      Lihat Detail Bagian
                                    </ListGroup.Item>
                                  </Link>

                                  <ListGroup.Item
                                    onClick={() => setOpenModalForm(true)}
                                  >
                                    <Link>Edit</Link>
                                  </ListGroup.Item>

                                  {/* edit form */}

                                  <Modal show={openModalForm} size="md" popup>
                                    <form
                                      onSubmit={(e) => updateData(e, ele._id)}
                                    >
                                      <Modal.Header
                                        onClick={() => closeModalForm(ele._id)}
                                      />

                                      <Modal.Body>
                                        <div className="space-y-6">
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="nomor_bagian"
                                                value="Masukkan Thumbnail Baru"
                                              />
                                            </div>
                                            <div className="flex flex-col gap-3  items-end">
                                              <div className="h-64 w-full">
                                                {image ? (
                                                  <img src={image} />
                                                ) : (
                                                  <FileUpload />
                                                )}
                                              </div>
                                              <label
                                                for="actual-btn"
                                                className={`cursor-pointer font-Jakarta text-sm text-gray-400 px-3 py-2 rounded-lg border border-gray-400 ${
                                                  file ? "block" : "hidden"
                                                }`}
                                                color="gray"
                                              >
                                                Ubah Gambar
                                              </label>
                                              <input
                                                type="file"
                                                className="font-Jakarta text-sm bg-brand-500 py-2 px-4 rounded"
                                                id="actual-btn"
                                                onChange={handleFileChange}
                                                hidden
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="nomor_bagian"
                                                value="Masukkan Judul Baru"
                                              />
                                            </div>
                                            <TextInput
                                              id="nomor_bagian"
                                              required
                                              onChange={(e) =>
                                                setJudulRole(e.target.value)
                                              }
                                            />
                                          </div>

                                          <div className="w-full">
                                            <Button type="submit">
                                              Update Data
                                            </Button>
                                          </div>
                                        </div>
                                      </Modal.Body>
                                    </form>
                                  </Modal>

                                  {/* edit form */}

                                  <button
                                    type="submit"
                                    className="w-full cursor-pointer"
                                  >
                                    <button
                                      type="submit"
                                      className="w-full cursor-pointer"
                                    >
                                      <ListGroup.Item
                                        onClick={() =>
                                          setOpenModal((prev) => !prev)
                                        }
                                      >
                                        Hapus Episode
                                        <Modal
                                          show={openModal}
                                          size="md"
                                          onClick={() => closeModalForm}
                                          popup
                                        >
                                          <Modal.Header
                                            onClick={() => closeModalForm}
                                          />
                                          <Modal.Body>
                                            <div className="text-center">
                                              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                                Are you sure you want to delete
                                                this product?
                                              </h3>
                                              <div className="flex justify-center gap-4">
                                                <Button
                                                  color="failure"
                                                  onClick={(e) =>
                                                    deleteData(e, ele._id)
                                                  }
                                                >
                                                  {"Yes, I'm sure"}
                                                </Button>
                                                <Button
                                                  color="gray"
                                                  onClick={() => closeModalForm}
                                                >
                                                  No, cancel
                                                </Button>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </ListGroup.Item>
                                    </button>
                                  </button>
                                </ListGroup>
                              </div>

                              <img src="/solutions.jpg" alt="" />
                            </motion.div>
                          </Popover.Panel>
                        </Popover>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </div>
      </div>
    </Flowbite>
  );
};

export default Dashboard;
