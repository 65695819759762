import { Logo, Chevron, menuIcon, logoBapli } from "../../assets/index.js";
import { BtnPrimary, NavLinks } from "../index.js";
import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import "../../App.css";
import {
  informasiItems,
  navbarItems,
  produkItems,
  solusiItems,
} from "../../constant";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const Navbar = ({ classname }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(true);
  const [solusi, setSolusi] = useState(true);
  const [informasi, setInformasi] = useState(false);
  const [produk, setProduk] = useState(false);
  const [menuUtama, setMenuUtama] = useState(true);
  const [isButton, setIsButton] = useState(true);

  const Chip = ({ text }) => (
    <button
      onClick={() => handleClick(4)}
      className="chip px-4 py-2 w-fit bg-gray-200 text-gray-950 text-small-semibold rounded-full flex items-center gap-1"
    >
      <Chevron classname="fill-gray-900 rotate-180" />
      {text}
    </button>
  );

  const ListItems = ({ title, image, desc }) => (
    <a href="" className={`group w-full`}>
      <div className="list flex flex-row gap-3 bg-white hover:bg-gray-200 p-1 rounded-lg items-center">
        <div className="main-container flex items-center gap-2">
          <div className="img-container rounded-lg">
            <img src={image} alt="" className="w-[32px]" />
          </div>
          <div className="text-container">
            <header className="text-base-bold text-gray-900">{title}</header>
            <p className="text-small-medium text-gray-700">{desc}</p>
          </div>
        </div>
      </div>
    </a>
  );

  const handleClick = (id) => {
    switch (id) {
      case 1:
        setProduk((prev) => true);
        setInformasi((prev) => false);
        setSolusi((prev) => false);
        setMenuUtama((prev) => false);
        setIsButton((prev) => false);

        break;
      case 2:
        setProduk((prev) => false);
        setSolusi((prev) => true);
        setInformasi((prev) => false);
        setMenuUtama((prev) => false);
        setIsButton((prev) => false);
        break;
      case 3:
        setProduk((prev) => false);
        setSolusi((prev) => false);
        setInformasi((prev) => true);
        setMenuUtama((prev) => false);
        setIsButton((prev) => false);
        break;
      case 4:
        setProduk((prev) => false);
        setSolusi((prev) => false);
        setInformasi((prev) => false);
        setMenuUtama((prev) => true);
        setIsButton((prev) => true);
    }
  };

  return (
    <>
      <nav
        id="navbar"
        className="px-5 z-10 overflow-hidden bg-white lg:px-[120px] h-fit border-b transition-max-height transit duration-300 border-gray-300  flex flex-col md:flex-row items-center py-4"
      >
        <div className="logoitem-container flex items-end gap-11 w-full md:w-full transition-all duration-300">
          <div className="flex flex-row justify-between w-full md:w-fit items-center">
            <Link to="/">
              <img src={logoBapli} className="max-w-[400px]" alt="" srcset="" />
            </Link>
            <img
              src={menuIcon}
              alt=""
              srcset=""
              className="flex md:hidden w-[24px]"
              onClick={() => setIsOpen((prev) => !prev)}
            />
          </div>
          {/* items-container */}
          <div className="items-container gap-8 hidden md:flex">
            <NavLinks classname={classname} />
          </div>
          {/* items-container */}
        </div>
        <AnimatePresence>
          <div
            id="element"
            className={` h-fit md:w-fit  w-full ${
              isOpen
                ? "max-h-screen pt-8  visibility-visible opacity-100"
                : "max-h-0 pt-0  visibility-hidden opacity-0"
            } transition-all duration-300 flex flex-col   gap-9 justify-start overflow-hidden`}
          >
            {menuUtama ? (
              <motion.div
                key="menuUtama"
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -100, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <ul>
                  {navbarItems.map((ele, index) => (
                    <div className="text-gray-900 text-lg-medium ">
                      {ele.id < 4 ? (
                        <li
                          onClick={() => handleClick(ele.id)}
                          href=""
                          className="flex items-center gap-2  px-2 py-3"
                        >
                          {ele.item}
                          <Chevron
                            classname={`${
                              ele.id < 4 ? "block" : "hidden"
                            } fill-gray-600`}
                          />
                        </li>
                      ) : (
                        <Link
                          to={`${
                            ele.id === 5
                              ? "/price"
                              : ele.id === 6
                              ? "/about"
                              : ""
                          }`}
                          className={`${
                            ele.id > 4
                          } px-2 py-3 inline-block w-full`}
                        >
                          {ele.item}
                        </Link>
                      )}
                    </div>
                  ))}
                </ul>
              </motion.div>
            ) : produk ? (
              <motion.div
                key="produk"
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -200, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <div
                  className={` flex flex-col gap-5 transition-all duration-200 ${
                    produk ? "translate-x-0" : "translate-x-64"
                  } `}
                >
                  <div className="items flex flex-col gap-2">
                    {produkItems.map((ele, index) => (
                      <ListItems
                        image={ele.img}
                        title={ele.title}
                        desc={ele.desc}
                      />
                    ))}
                  </div>

                  <Chip text="Produk" />
                </div>
              </motion.div>
            ) : solusi ? (
              <motion.div
                key="solusi"
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -200, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                <div className="flex flex-col gap-5">
                  <div className="items flex flex-col gap-2">
                    {solusiItems.map((ele, index) => (
                      <ListItems
                        image={ele.img}
                        title={ele.title}
                        desc={ele.desc}
                      />
                    ))}
                  </div>

                  <Chip text="Solusi" />
                </div>
              </motion.div>
            ) : (
              <>
                <motion.div
                  key="informasi"
                  initial={{ x: -200, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -200, opacity: 0 }}
                  transition={{ duration: 0.4, ease: "easeInOut" }}
                >
                  <div className="flex flex-col gap-5">
                    <div className="items flex flex-col gap-2">
                      {informasiItems.map((ele, index) => (
                        <ListItems
                          image={ele.img}
                          title={ele.title}
                          desc={ele.desc}
                        />
                      ))}
                    </div>

                    <Chip text="Informasi" />
                  </div>
                </motion.div>
              </>
            )}

            <div
              className={`button-container  items-start gap-2  flex flex-col min-w-fit ${
                isButton ? "block" : "hidden"
              }`}
            >
              {/* <BtnSecondary text="Login" classname="w-fit" icon="hidden" /> */}
              <BtnPrimary
                link="https://wa.me/+6282240040640"
                text="Chat Admin Kami"
                classname="w-fit"
              />
            </div>
          </div>
        </AnimatePresence>
        <div className="button-container  items-center  gap-2 hidden md:flex min-w-fit">
          <BtnPrimary
            link="https://wa.me/+6282240040640"
            text="Chat Admin Kami"
            classname="w-full"
          />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
