import React from "react";
import { pricingItems } from "../constant/index.js";
import { useMediaQuery } from "react-responsive";
import "../App.css";

import { Navbar, Information, FooterSection } from "../components/index.js";
import { checkIcon } from "../assets/index.js";
import { BtnPrimary } from "../components/index.js";

const PricingPage = () => {
  const IsSmall = useMediaQuery({ maxWidth: "768px" });

  const Header = () => (
    <>
      <span className="text-lg-bold uppercase text-brand-500 text-center block mt-[62px] mb-2">
        Ragam harga produk digital Bapli School
      </span>
      <h1
        className={`${
          IsSmall ? "text-3xl-semibold" : "text-4xl-semibold"
        } text-gray-900 text-center `}
      >
        Jaminan produk digital Pendidikan Terlengkap dengan Harga Termurah
      </h1>
    </>
  );

  const CardPricing = ({ header, price, subHeader, children }) => (
    <div
      id="price-card"
      className="price-container p-6  rounded-xl bg-white border border-gray-300  w-full max-w-[380px]  hover:translate-y-2 transition-all duration-200"
    >
      <div className="content flex flex-col gap-6">
        <div className="header flex flex-col gap-3">
          <h1 id="title-pricing" className="text-2xl-semibold">
            {header}
          </h1>
          <p className="text-lg-medium text-gray-700">{subHeader}</p>
        </div>
        <div className="h-[1px] w-full bg-gray-300"></div>
        <div className="price flex flex-col gap-5">
          <div className="title-header">
            <span className="text-small-medium text-gray-800">MULAI DARI</span>

            <div className="price flex gap-1 items-baseline">
              <h1 className="text-2xl-extrabold text-gray-900">{price}</h1>
              <span className="text-base-regular text-gray-700">/ month</span>
            </div>
          </div>

          <BtnPrimary text="Hubungi Admin Sales" />
        </div>
        <div className="h-[1px] w-full bg-gray-300"></div>
        <div className="list-container flex flex-col gap-5">
          <span className="text-small-medium text-gray-700">
            Semua yang akan anda dapatkan di paket ini :{" "}
          </span>
          <div className="list">{children}</div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="main-contain bg-gray-50 mb-[200px]">
        <nav>
          <Information />
          <Navbar />
        </nav>
        <div className="main-container px-5 md:px-[140px]">
          <header className="mb-14">
            <Header />
          </header>
          <div className="price-card-container flex flex-wrap  gap-6 justify-center mb-16">
            {pricingItems.map((ele, index) => (
              <CardPricing
                header={ele.header}
                price={ele.price}
                subHeader={ele.subHeader}
              >
                <div className="list">
                  <ul className="flex flex-col gap-4">
                    {ele.benefitItems.map((ele, index) => (
                      <li className="flex gap-3 text-base-medium text-gray-800">
                        <img src={checkIcon} alt="" srcset="" />
                        {ele.item}
                      </li>
                    ))}
                  </ul>
                </div>
              </CardPricing>
            ))}
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default PricingPage;
