const Arrow = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.76614 3.80079C8.98614 3.55382 9.3651 3.53158 9.61262 3.7511L13.8986 7.5528C14.0267 7.66634 14.0999 7.82908 14.0999 7.99998C14.0999 8.17086 14.0267 8.33358 13.8986 8.44718L9.61262 12.2489C9.3651 12.4684 8.98614 12.4461 8.76614 12.1992C8.54614 11.9522 8.56838 11.5741 8.8159 11.3545L11.9233 8.5983H3.49949C3.16834 8.5983 2.8999 8.33038 2.8999 7.99998C2.8999 7.66954 3.16834 7.40168 3.49949 7.40168H11.9233L8.8159 4.64546C8.56838 4.42593 8.54614 4.04776 8.76614 3.80079Z"
        fill="white"
      />
    </svg>
  );
};

export default Arrow;
