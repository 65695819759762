import { HashRouter as Router, Routes, Route } from "react-router-dom";
import {
  LandingPage,
  PricingPage,
  AboutPage,
  KategoriAplikasi,
  KategoriRole,
  KategoriBagian,
  Dashboard,
  MainCourse,
  Bagian,
  Episode,
} from "./pages/index.js";
import { Flowbite } from "flowbite-react";

function App() {
  const customTheme = {
    dark: false,
    // Anda bisa menambahkan pengaturan tema lainnya di sini
  };
  return (
    <>
      <Flowbite theme={{ theme: customTheme.dark }}>
        <Router>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/price" exact element={<PricingPage />} />
            <Route path="/about" exact element={<AboutPage />} />
            <Route path="/academy" exact element={<KategoriAplikasi />} />
            <Route path="/academy/role" exact element={<KategoriRole />} />
            <Route
              path="/academy/role/:id/bagian"
              exact
              element={<KategoriBagian />}
            />
            <Route
              path="/academy/role/:id/:bagian/:idBagian/:index"
              exact
              element={<MainCourse />}
            />
            <Route path="/test" exact element={<MainCourse />} />
            <Route path="/admin/dashboard" exact element={<Dashboard />} />
            <Route path="/admin/dashboard/:id" exact element={<Bagian />} />
            <Route
              path="/admin/dashboard/:id/bagian/:idBagian/tutorial"
              exact
              element={<Episode />}
            />
          </Routes>
        </Router>
      </Flowbite>
    </>
  );
}

export default App;
