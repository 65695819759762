import { personFun } from "../../assets/index.js";
import { BtnPrimary } from "../index.js";
const PersuasiSection = () => {
  return (
    <section className="section-persuasi w-full bg-gray-900 h-fit">
      <div className="main-content w-full px-5 md:px-[140px] py-[60px] lg:py-0 md:pt-8 flex flex-col items-center md:flex-row grid-cols-12 gap-36">
        <img
          src={personFun}
          alt=""
          srcset=""
          className="col-span-5 hidden w-full max-w-[400px] xl:max-w-[600px] lg:flex"
        />

        <div className="text-content col-start-6 col-end-12    text-white flex flex-col gap-8">
          <header className=" flex flex-col gap-4">
            <h1 className="text-3xl-bold md:text-4xl-bold">
              Siap untuk sistem akademik yang lebih mudah dan efisien?
            </h1>
            <p className="text-xl-medium">
              Mulai gunakan produk Bapli School dan ubah sistem akademik menjadi
              lebih efisien!
            </p>
          </header>
          <BtnPrimary
            link="https://wa.me/+6282240040640"
            text="Hubungi Admin Baplischool"
            classname="w-fit"
          />
        </div>
      </div>
    </section>
  );
};

export default PersuasiSection;
