import React from "react";
import { BtnPrimary, Header } from "../index.js";
import { imageData } from "../../assets/index.js";
import { useMediaQuery } from "react-responsive";
import { dataReasonDua, dataReasonSatu } from "../../constant/index.js";

const ReasonSection = () => {
  const isMedium = useMediaQuery({ minWidth: "960px" });
  const isLarge = useMediaQuery({ minWidth: "1700px" });

  const textSizeTitle = isLarge
    ? "text-7xl-extrabold"
    : isMedium
    ? "text-5xl-extrabold"
    : "text-4xl-extrabold";

  const textSizeDesc = isLarge
    ? "text-xl-medium"
    : isMedium
    ? "text-lg-medium"
    : "text-base-medium";
  const headerData = {
    title: "Optimisasi Manajemen Pesantren dalam satu Web ",
    subtitle: "Alasan anda harus menggunakan produk Bapli School",
    desc: "Nikmati keunggulan fitur unik Edutren yang dirancang khusus untuk memenuhi kebutuhan spesifik Anda",
  };

  const ItemsList = ({ title, desc }) => (
    <div className="flex flex-col gap-2">
      <span className="pl-2 border-l-2 border-brand-500 text-xl-extrabold text-gray-900">
        {title}
      </span>
      <p className="text-base text-base-medium text-gray-600">{desc}</p>
    </div>
  );
  return (
    <section className="reason-section  py-16 px-5 md:px-[140px]">
      <div className=" flex flex-col md:grid grid-cols-1 md:grid-cols-12 justify-between items-center gap-8 xl:mb-0 mb-8">
        <div className="text-container flex flex-col md:col-span-6 gap-11 ">
          <Header
            title={headerData.title}
            desc={headerData.desc}
            subtitle={headerData.subtitle}
            button="hidden"
          />
          <div className="item-container w-full flex flex-col gap-8">
            <div className="item-row flex flex-col gap-7  sm:flex-row ">
              {dataReasonSatu.map((ele, index) => (
                <ItemsList key={ele.id} title={ele.title} desc={ele.desc} />
              ))}
            </div>
            <div className="item-row flex flex-col gap-7 sm:flex-row">
              {dataReasonDua.map((ele, index) => (
                <ItemsList key={ele.id} title={ele.title} desc={ele.desc} />
              ))}
            </div>
          </div>
        </div>
        <img
          src={imageData}
          alt=""
          srcset=""
          className="md:col-start-8 col-end-13 w-full mb-10 max-w-[750px]"
        />
      </div>
      <BtnPrimary
        text="Hubungi Admin Baplischool"
        classname="mx-auto md:mx-0"
        link="https://wa.me/+6282240040640"
      />
    </section>
  );
};

export default ReasonSection;
