import React, { useEffect, useState } from "react";
import { imageSmile, notFound } from "../../assets";
import { AdminNav, Sidebarr } from "../../components";
import { academyData, footerItems } from "../../constant";
import { Link, useParams } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { Popover } from "@headlessui/react";
import { motion } from "framer-motion";
import { HiOutlineExclamationCircle, HiHome } from "react-icons/hi";
import {
  Card,
  ListGroup,
  Button,
  Checkbox,
  Label,
  Modal,
  Table,
  TextInput,
  Textarea,
  FileInput,
} from "flowbite-react";
import axios from "axios";
import { Toast, Breadcrumb } from "flowbite-react";

const Bagian = () => {
  const { id } = useParams();
  const [dataGuru, setDataGuru] = useState([]);
  const [dataRaw, setDataRaw] = useState([]);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [titleBagian, setTitleBagian] = useState("");
  const [image, setImage] = useState();

  const [bagian, setBagian] = useState("");
  const [openModalForm, setOpenModalForm] = useState(false);

  // data post
  const [file, setFile] = useState(null);
  const [nomorBagian, setNomorBagian] = useState("");
  const [judulBagian, setJudulBagian] = useState("");
  const [deskripsiBagian, setDeskripsiBagian] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // data post

  const closeModalForm = () => {
    setOpenModalForm((prev) => !prev);
  };

  const handleFileChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  // data fetching

  const fetchDataGuru = async () => {
    try {
      const result = await axios.get("http://localhost:3001/api/roles");
      setDataGuru(result.data.filter((ele, index) => ele._id == id));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    const result = await axios.get(
      ` http://localhost:3001/api/role/${id}/bagian`
    );
    setDataRaw(result.data);
    setNomorBagian(result.data.map((ele, index) => ele.nomor_bagian));
    setJudulBagian(result.data.map((ele, index) => ele.judul_bagian));
    setDeskripsiBagian(result.data.map((ele, index) => ele.deskripsi_bagian));

    setDataRaw(result.data);
  };

  useEffect(() => {
    fetchData();
    fetchDataGuru();
  }, []);

  const deleteData = async (e, idBagian) => {
    e.preventDefault();
    try {
      const result = await axios.delete(
        `http://localhost:3001/api/role/${id}/bagian/${idBagian}`
      );
      console.log(result.status);
    } catch (error) {
      console.error(error);
    }
    fetchData();
    setOpenModal((prev) => !prev);
  };

  const updateData = async (e, id, idBagian) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("image", file);
    formData.append("judul_bagian", judulBagian);
    formData.append("deskripsi_bagian", deskripsiBagian);
    formData.append("nomor_bagian", nomorBagian);

    try {
      const result = await axios.put(
        `http://localhost:3001/api/role/${id}/bagian/${idBagian}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(result.data);
      fetchData();
      closeModalForm();
    } catch (error) {
      console.log(error);
    }
  };

  const postData = async (e, id) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("image", file);
    formData.append("judul_bagian", judulBagian);
    formData.append("deskripsi_bagian", deskripsiBagian);
    formData.append("nomor_bagian", nomorBagian);

    try {
      const result = await axios.post(
        `http://localhost:3001/api/role/${id}/bagian`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setOpenModalCreate((prev) => !prev);
      fetchData();
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const basePath = "/baplischool";

  // component
  const FileUpload = () => {
    return (
      <div onChange={handleFileChange} className="w-full">
        <div className="flex w-full items-center justify-center">
          <Label
            htmlFor="dropzone-file"
            className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
            <FileInput id="dropzone-file" className="hidden" />
          </Label>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="main-container">
        <AdminNav />
        <div className="main-container flex bg-gray-200 dark:bg-gray-900">
          <Sidebarr />

          {dataRaw.length == 0 ? (
            <>
              <div className="flex flex-col items-center mx-auto pt-20  ">
                <img src={notFound} className="w-72" alt="" srcset="" />
                <div className="flex flex-col gap-4 items-center ">
                  <h1 className="text-2xl-semibold text-center text-gray-800">
                    Ups, belum ada data konten, buat yang baru yuk!
                  </h1>
                  <Button
                    className="mb-4"
                    onClick={() => setOpenModalCreate((prev) => !prev)}
                  >
                    Tambah
                  </Button>
                  <Modal show={openModalCreate} size="md" popup>
                    <form onSubmit={(e) => postData(e, id)}>
                      <Modal.Header
                        onClick={() => setOpenModalCreate((prev) => !prev)}
                      />
                      <Modal.Body>
                        {/* create data */}
                        <div className="space-y-2">
                          <div>
                            <div className="mb-2 block">
                              <Label
                                htmlFor="upload_thumbnail"
                                value="Upload Thumbnail"
                              />
                            </div>
                            {/* file upload */}
                            <div className="flex flex-col gap-3  items-end">
                              <div className="h-64 w-full">
                                {image ? <img src={image} /> : <FileUpload />}
                              </div>
                              <label
                                for="actual-btn"
                                className={`cursor-pointer font-Jakarta text-sm text-gray-700 px-3 py-2 rounded-lg border border-gray-500 ${
                                  file ? "block" : "hidden"
                                }`}
                                color="gray"
                              >
                                Ubah Gambar
                              </label>
                              <input
                                type="file"
                                className="font-Jakarta text-sm bg-brand-500 py-2 px-4 rounded"
                                id="actual-btn"
                                onChange={handleFileChange}
                                hidden
                              />
                            </div>
                            {/* file upload */}
                          </div>
                          <div>
                            <div className="mb-2 block">
                              <Label
                                htmlFor="judul_bagian"
                                value="Nomor Bagian"
                              />
                            </div>
                            <TextInput
                              id="judul_role"
                              required
                              onChange={(e) => setNomorBagian(e.target.value)}
                            />
                          </div>
                          <div>
                            <div className="mb-2 block">
                              <Label htmlFor="judul_bagian" value="Judul " />
                            </div>
                            <TextInput
                              id="judul_role"
                              required
                              onChange={(e) => setJudulBagian(e.target.value)}
                            />
                          </div>
                          <div>
                            <div className="mb-2 block">
                              <Label
                                htmlFor="deskripsi_bagian"
                                value="Deskripsi Bagian"
                              />
                            </div>
                            <TextInput
                              id="judul_role"
                              required
                              onChange={(e) =>
                                setDeskripsiBagian(e.target.value)
                              }
                            />
                          </div>

                          <div className="w-full">
                            <Button type="submit">Kirim</Button>
                          </div>
                        </div>
                        {/* create data */}
                      </Modal.Body>
                    </form>
                  </Modal>
                </div>
              </div>
            </>
          ) : (
            <div className="overflow-x-auto w-full px-8 pt-8">
              <div
                id="breadcrumb"
                className="flex items-center justify-between"
              >
                <div>
                  <Breadcrumb
                    className="mb-2"
                    aria-label="Default breadcrumb example"
                  >
                    <Breadcrumb.Item
                      href={`${basePath}/#/admin/dashboard`}
                      icon={HiHome}
                    >
                      Data Role
                    </Breadcrumb.Item>
                    {dataGuru &&
                    dataGuru.length > 0 &&
                    dataGuru[0].judul_role ? (
                      <Breadcrumb.Item
                        href={`${basePath}/#/admin/dashboard/${id}`}
                      >
                        <span>{dataGuru[0].judul_role}</span>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item
                        href={`${basePath}/#/admin/dashboard/${id}`}
                      >
                        Bagian Role <span>(Judul Role Tidak Tersedia)</span>
                      </Breadcrumb.Item>
                    )}
                  </Breadcrumb>
                  <h1 className="text-2xl font-semibold mb-10 font-Jakarta text-gray-900">
                    Data Konten Bagian{" "}
                    {dataGuru &&
                    dataGuru.length > 0 &&
                    dataGuru[0].judul_role ? (
                      <span className="font-extrabold">
                        Role <span>{dataGuru[0].judul_role}</span>
                      </span>
                    ) : (
                      <span className="font-extrabold">
                        (Judul Role Tidak Tersedia)
                      </span>
                    )}{" "}
                    Edutren
                  </h1>
                </div>
                <Button onClick={() => setOpenModalCreate((prev) => !prev)}>
                  Tambah Konten
                </Button>
              </div>

              {/* create modal */}
              <Modal className="h-fit" show={openModalCreate} size="md" popup>
                <form className="h-fit" onSubmit={(e) => postData(e, id)}>
                  <Modal.Header
                    onClick={() => setOpenModalCreate((prev) => !prev)}
                  />
                  <Modal.Body>
                    {/* create data */}
                    <div className="space-y-3 h-fit">
                      <div className="h-fit">
                        <div className="mb-2 block">
                          <Label
                            htmlFor="upload_thumbnail"
                            value="Upload Thumbnail"
                          />
                        </div>
                        <div>
                          <div className="mb-2 block"></div>
                          <div className="flex flex-col gap-3  items-end">
                            <div className="h-64 w-full">
                              {image ? <img src={image} /> : <FileUpload />}
                            </div>
                            <label
                              for="actual-btn"
                              className={`cursor-pointer font-Jakarta text-sm text-gray-700 px-3 py-2 rounded-lg border border-gray-500 ${
                                file ? "block" : "hidden"
                              }`}
                              color="gray"
                            >
                              Ubah Gambar
                            </label>
                            <input
                              type="file"
                              className="font-Jakarta text-sm bg-brand-500 py-2 px-4 rounded"
                              id="actual-btn"
                              onChange={handleFileChange}
                              hidden
                            />
                          </div>
                        </div>
                      </div>
                      <div></div>
                      <div>
                        <div className="mb-2 block">
                          <Label htmlFor="judul_bagian" value="Nomor Bagian" />
                        </div>
                        <TextInput
                          id="judul_role"
                          required
                          onChange={(e) => setNomorBagian(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label htmlFor="judul_bagian" value="Judul Bagian" />
                        </div>
                        <TextInput
                          id="judul_role"
                          required
                          onChange={(e) => setJudulBagian(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="deskripsi_bagian"
                            value="Deskripsi Bagian"
                          />
                        </div>
                        <TextInput
                          id="judul_role"
                          required
                          onChange={(e) => setDeskripsiBagian(e.target.value)}
                        />
                      </div>

                      <div className="w-full">
                        <Button type="submit">Tambah konten</Button>
                      </div>
                    </div>
                    {/* create data */}
                  </Modal.Body>
                </form>
              </Modal>
              {/* create modal */}
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>No</Table.HeadCell>
                  <Table.HeadCell>Thumbnail</Table.HeadCell>
                  <Table.HeadCell>Judul Bagian</Table.HeadCell>
                  <Table.HeadCell>Deskripsi Bagian </Table.HeadCell>
                  <Table.HeadCell>Aksi</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {dataRaw.map((ele, index) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {index + 1}
                      </Table.Cell>
                      <Table.Cell>
                        <img
                          src={`http://localhost:3001/api/getImage/${ele.image_bagian}`}
                          alt="image thumbnail"
                          className=" w-24 rounded-lg"
                        />
                      </Table.Cell>
                      <Table.Cell>{ele.judul_bagian}</Table.Cell>
                      <Table.Cell>{ele.deskripsi_bagian}</Table.Cell>

                      <Table.Cell>
                        <Popover className="relative">
                          <Popover.Button>
                            <HiDotsVertical />
                          </Popover.Button>

                          <Popover.Panel className="absolute z-10 right-44">
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <div className="grid grid-cols-2">
                                <ListGroup className="w-48 shadow-2xl shadow-gray-300">
                                  <ListGroup.Item href="#">
                                    <Link
                                      to={`/admin/dashboard/${id}/bagian/${ele._id}/tutorial`}
                                    >
                                      Lihat Episode Bagian
                                    </Link>
                                  </ListGroup.Item>

                                  <ListGroup.Item
                                    onClick={() => setOpenModalForm(true)}
                                  >
                                    Edit Detail
                                  </ListGroup.Item>
                                  <Modal show={openModalForm} size="md" popup>
                                    <Modal.Header
                                      onClick={() => closeModalForm()}
                                    />
                                    <Modal.Body>
                                      <form
                                        onSubmit={(e) =>
                                          updateData(e, id, ele._id)
                                        }
                                      >
                                        <div className="space-y-6">
                                          <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                            Edit Data Bagian
                                          </h3>

                                          {/* file upload */}
                                          <div className="flex flex-col gap-3  items-end">
                                            <div className="h-64 w-full">
                                              {image ? (
                                                <img src={image} />
                                              ) : (
                                                <FileUpload />
                                              )}
                                            </div>
                                            <label
                                              for="actual-btn"
                                              className={`cursor-pointer font-Jakarta text-sm text-gray-700 px-3 py-2 rounded-lg border border-gray-500 ${
                                                file ? "block" : "hidden"
                                              }`}
                                              color="gray"
                                            >
                                              Ubah Gambar
                                            </label>
                                            <input
                                              type="file"
                                              className="font-Jakarta text-sm bg-brand-500 py-2 px-4 rounded"
                                              id="actual-btn"
                                              onChange={handleFileChange}
                                              hidden
                                            />
                                          </div>
                                          {/* file upload */}
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="nomor_bagian"
                                                value="Edit Nomor Bagian"
                                              />
                                            </div>
                                            <TextInput
                                              id="nomor_bagian"
                                              placeholder={ele.nomor_bagian}
                                              onChange={(event) =>
                                                setNomorBagian(
                                                  event.target.value
                                                )
                                              }
                                              required
                                            />
                                          </div>
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="judul_bagian"
                                                value="Edit Judul Bagian"
                                              />
                                            </div>
                                            <TextInput
                                              id="judul_bagian"
                                              placeholder={ele.judul_bagian}
                                              className="placeholder-gray-900"
                                              onChange={(event) =>
                                                setTitleBagian(
                                                  event.target.value
                                                )
                                              }
                                              required
                                            />
                                          </div>
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="password"
                                                value="Edit Deksripsi Bagian"
                                              />
                                            </div>

                                            <Textarea
                                              id="comment"
                                              placeholder={ele.deskripsi_bagian}
                                              required
                                              rows={4}
                                            />
                                          </div>
                                          <div className="flex justify-between">
                                            <div className="flex items-center gap-2">
                                              <Checkbox id="remember" />
                                              <Label htmlFor="remember">
                                                Remember me
                                              </Label>
                                            </div>
                                            <a
                                              href="#"
                                              className="text-sm text-cyan-700 hover:underline dark:text-cyan-500"
                                            >
                                              Lost Password?
                                            </a>
                                          </div>
                                          <div className="w-full">
                                            <Button type="submit">
                                              Update
                                            </Button>
                                          </div>
                                        </div>
                                      </form>
                                    </Modal.Body>
                                  </Modal>

                                  <button
                                    type="submit"
                                    className="w-full cursor-pointer"
                                  >
                                    <button
                                      type="submit"
                                      className="w-full cursor-pointer"
                                    >
                                      <ListGroup.Item
                                        onClick={() =>
                                          setOpenModal((prev) => !prev)
                                        }
                                      >
                                        Hapus Episode
                                      </ListGroup.Item>
                                      <Modal
                                        show={openModal}
                                        size="md"
                                        onClick={() =>
                                          setOpenModal((prev) => !prev)
                                        }
                                        popup
                                      >
                                        <Modal.Header
                                          onClick={() =>
                                            setOpenModal((prev) => !prev)
                                          }
                                        />
                                        <Modal.Body>
                                          <div className="text-center">
                                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                              Are you sure you want to delete
                                              this product?
                                            </h3>
                                            <div className="flex justify-center gap-4">
                                              <Button
                                                color="failure"
                                                onClick={(e) =>
                                                  deleteData(e, ele._id)
                                                }
                                              >
                                                {"Yes, I'm sure"}
                                              </Button>
                                              <Button
                                                color="gray"
                                                onClick={() =>
                                                  setOpenModal((prev) => !prev)
                                                }
                                              >
                                                No, cancel
                                              </Button>
                                            </div>
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                    </button>
                                  </button>
                                </ListGroup>
                              </div>

                              <img src="/solutions.jpg" alt="" />
                            </motion.div>
                          </Popover.Panel>
                        </Popover>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <img src={file} alt="" srcset="" />
                {/* <button onClick={show}>Show</button> */}
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Bagian;
