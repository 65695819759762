import { twMerge } from "tw-merge";
import { Whatsapp, Chevron } from "../assets/index.js";

const BtnSecondary = ({ icon, arrow, text, classname }) => {
  return (
    <button
      className={twMerge(
        `text-white bg-white px-5 py-3 rounded-lg w-full flex items-center gap-2 border border-brand-500 md:w-fit flex-row ${classname}`,
        classname
      )}
    >
      <div className="flex items-center w-fit">
        <div className="whatsapp-icon flex items-center gap-1">
          <Whatsapp classname={`fill-brand-500 ${icon}`} />
          <p className="text-base-semibold text-brand-500">{text}</p>
        </div>
        <Chevron classname={`fill-brand-500 ${arrow}`} />
      </div>
    </button>
  );
};

export default BtnSecondary;
