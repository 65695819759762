import React, { useEffect, useState } from "react";
import { BtnPrimary, Cta, FooterSection, Navbar } from "../components/index.js";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const KategoriBagian = () => {
  const isSmall = useMediaQuery({ maxWidth: "768px" });
  const { id } = useParams();
  const [dataRaw, setDataRaw] = useState([]);

  const onClickFunction = (event) => {
    event.preventDefault();
    window.history.back();
  };

  const fetchData = async () => {
    try {
      const result = await axios.get(
        `http://localhost:3001/api/role/${id}/bagian`
      );
      setDataRaw(result.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Card = ({ title, nomor, idKursus, desc, imageBagian, index }) => (
    <>
      <Link to={`/academy/role/${id}/bagian/${idKursus}/${index}`}>
        <div className="card flex items-center flex-col md:flex-row gap-4">
          <img
            src={imageBagian}
            className="img w-full max-w-full md:max-w-[200px] h-[200px] md:h-[150px]  rounded-lg"
          />

          <div className="content-container flex flex-col gap-2 md:gap-4">
            <div className="text-header">
              <span className="text-small-bold text-brand-500">
                BAGIAN {nomor}
              </span>
              <h1
                className={`${
                  isSmall ? "text-lg-bold" : "text-2xl-bold"
                } text-gray-900`}
              >
                {title}
              </h1>
            </div>
            <p
              className={`${
                isSmall ? "text-small-medium" : "text-lg-medium"
              } text-gray-700`}
            >
              {desc}
            </p>
          </div>
        </div>
      </Link>
    </>
  );

  const CardDisabled = ({
    title,
    nomor,
    idKursus,
    desc,
    imageBagian,
    index,
  }) => (
    <>
      <Link to={`/academy/role/${id}/bagian/${idKursus}/${index}`}>
        <div className="card flex items-center flex-col md:flex-row gap-4">
          <img
            src={imageBagian}
            className="img w-full max-w-full md:max-w-[200px] grayscale h-[200px] md:h-[150px] bg-gray-950 rounded-lg"
          />

          <div className="content-container flex flex-col gap-2 md:gap-4">
            <div className="text-header">
              <span className="text-small-bold text-brand-500">
                BAGIAN {nomor}
              </span>
              <h1
                className={`${
                  isSmall ? "text-lg-bold" : "text-2xl-bold"
                } text-gray-900`}
              >
                {title}
              </h1>
            </div>
            <p
              className={`${
                isSmall ? "text-small-medium" : "text-lg-medium"
              } text-gray-700`}
            >
              {desc}
            </p>
          </div>
        </div>
      </Link>
    </>
  );

  return (
    <>
      <div className="fixed z-50 w-full transition-all duration-300">
        <Navbar classname="top-14" />
      </div>
      <div className="content-hero px-5 md:px-[200px] flex flex-col md:grid md:grid-cols-12 gap-6 items-center pt-12 md:pt-36 order-last md:order-first mb-24 md:mb-[145px]">
        <div className="content-info col-span-6 flex flex-col gap-4">
          <Cta
            onClickFunction={onClickFunction}
            text="Pelajari Role Lainnya"
            arrowOrder="order-first rotate-180"
          />
          <div className="content-contain pr-0 md:pr-[90px] w-fit flex flex-col gap-8">
            <div className="text-contain flex flex-col gap-4">
              <h1
                className={`${
                  isSmall ? "text-2xl-bold" : "text-4xl-bold"
                } text-gray-900`}
              >
                Belajar Role Guru Edutren
              </h1>
              <p
                className={`${
                  isSmall ? "text-small-medium" : "text-lg-medium"
                } text-gray-700`}
              >
                Mulai pelajari aplikasi Edutren. Ada berbagai pilihan
                pembelajaran role, dari guru, hingga orang tua murid!
              </p>
            </div>
            <BtnPrimary
              scroll="card-content"
              icon="hidden"
              classname="w-fit"
              text="Pilih bagian pembelajaran"
            />
          </div>
        </div>
        <div
          // style={{ backgroundImage: `url(${thumbnailRoleGuru})` }}
          className="image w-full bg-cover  h-[300px] order-first md:order-last col-span-6 rounded-lg"
        ></div>
      </div>
      <div className="content-container px-5 md:px-[200px] flex flex-col gap-14 mb-32">
        <h1 className="text-2xl-bold text-gray-900">
          Pilih bagian pembelajaran
        </h1>
        <div
          id="card-content"
          className="card-content flex flex-col gap-14 md:gap-6 max-w-[800px]"
        >
          {dataRaw.length == 0 ? (
            <div>Tidak ada data</div>
          ) : (
            <>
              {dataRaw.map((ele, index) => (
                <Card
                  title={ele.judul_bagian}
                  nomor={index + 1}
                  idKursus={ele._id}
                  desc={ele.deskripsi_bagian}
                  index={index}
                  imageBagian={`http://localhost:3001/api/getImage/${ele.image_bagian}`}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default KategoriBagian;
