import React from "react";
import { heroAnakKecil } from "../../assets/index.js";
import { BtnPrimary, BtnSecondary } from "../index.js";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";
import {
  Link as Scrolling,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

const HeroSection = () => {
  const isMedium = useMediaQuery({ minWidth: "960px" });
  const isLarge = useMediaQuery({ minWidth: "1700px" });

  const textSizeTitle = isLarge
    ? "text-7xl-extrabold"
    : isMedium
    ? "text-5xl-extrabold"
    : "text-4xl-extrabold";

  const textSizeDesc = isLarge
    ? "text-xl-medium"
    : isMedium
    ? "text-lg-medium"
    : "text-base-medium";

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "30vh",
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: "-30vh",
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        key="/"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={{ type: "tween", ease: "easeOut", duration: 0.6 }}
      >
        <div className="hero-section flex flex-col md:grid md:grid-cols-12 px-5 md:px-[140px] pt-[170px] ">
          <div className="text-container md:col-span-6 pt-0 md:pt-10 lg:pt-20 flex flex-col gap-9">
            <div className="text-header flex flex-col gap-4">
              <h1 className={textSizeTitle}>
                Bikin Mudah
                <span className="text-brand-500"> Sistem Akdemik</span> dengan
                Bapli School!
              </h1>
              <p className={`${textSizeDesc} text-gray-700`}>
                Produk digital Bapli School telah sukses memudahkan proses
                manajemen sekolah dan sistem akademik pesantren di Indonesia
              </p>
            </div>
            <div className="button-container flex flex-col md:flex-row items-center gap-2 ">
              <Scrolling
                activeClass="active"
                to="aboutSection"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="md:w-fit w-full"
              >
                <BtnSecondary
                  text="Explore lebih banyak"
                  icon="hidden"
                  classname="w-full justify-center order-2  "
                />
              </Scrolling>
              <BtnPrimary
                link="https://wa.me/+6282240040640"
                text="Hubungi Admin"
                classname="justify-center order-1  w-full md:w-fit"
              />
            </div>
          </div>

          <img
            className="hero-image hidden md:flex md:col-start-8 col-end-13 w-full"
            src={heroAnakKecil}
            alt=""
            srcset=""
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default HeroSection;
